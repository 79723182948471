import cn from 'classnames';
import styles from './style.module.scss';


type Item = {
  img: string;
  title: string;
  desc: string;
};

const list: Item[] = [
  {
    img: '/images/productsWeCertify/clothes.png',
    title: 'Одежда',
    desc: 'Одежда и изделия 2-го и 3-го слоев, футболки, пальто, войлок, фетр и нетканые материалы, обувь, меха, кожа, головные уборы',
  },
  {
    img: '/images/productsWeCertify/clothes1.png',
    title: 'Пищевая продукция',
    desc: 'Зерно, фрукты, овощи, рыба, мясо, сыры, хлеб и хлебобулочная продукция, напитки',
  },
  {
    img: '/images/productsWeCertify/clothes2.png',
    title: 'Оборудование и машины',
    desc: 'Машины, техника и электрическое, топливное и газовое оборудование, бытового и промышленного назначения',
  },
  {
    img: '/images/productsWeCertify/clothes3.png',
    title: 'Мебель и мебельная продукция',
    desc: 'Столы, стулья, корпусная, мягкая мебель, стеллажи, полки, шкафы',
  },
  {
    img: '/images/productsWeCertify/clothes4.png',
    title: 'Строительные материалы',
    desc: 'Тратуары, трубы, бордюры, цемент, кирпич, строительные смеси, краски',
  },
  {
    img: '/images/productsWeCertify/clothes5.png',
    title: 'Косметика',
    desc: 'Духи, одеколоны, косметическая продукция для макияжа лица, глаз, губ, бровей',
  },
  {
    img: '/images/productsWeCertify/clothes6.png',
    title: 'Упаковка',
    desc: 'бумажная и картонная, металлическая, полимерная, стеклянная, деревянная',
  },
  {
    img: '/images/productsWeCertify/clothes7.png',
    title: 'Бытовая техника',
    desc: 'Аппараты и устройства для повседневного использования,  устройства для готовки и приготовления пищи',
  },
  {
    img: '/images/productsWeCertify/clothes8.png',
    title: 'Оборудование под избыточным давлением',
    desc: 'Сосуды, трубопроводы и арматура, элементы оборудования и систем контроля и безопасности, барокамеры',
  },
];

export const ProductsWeCertify = () => (
  <div className={ styles.productsWeCertify }>
    <div className="container">
      <div className="columns">
        <div className={ cn([styles.heading, 'heading']) }>
          <h2>Продукция, которую мы сертифицируем</h2>
        </div>
      </div>
      <div className={ styles.list }>
        { list.map((item, i) => (
          <div className={ styles.item } key={ i }>
            <div className={ styles.image }>
              <img src={ item.img } alt={ item.title } />
            </div>
            <div className={ styles.content }>
              <div className={ styles.title }>{ item.title }</div>
              <div className={ styles.desc }>{ item.desc }</div>
            </div>
          </div>
        )) }
      </div>
    </div>
  </div>
);
