import React, { useState } from 'react';
import styles from './styles.module.scss';


type TChildren = {
  title: string;
};

type Props = {
  list: Array<{
    title: string;
    children?: TChildren[];
  }>;
  limit?: number;
};


function menu(items: any) {
  const list = [];

  for (let i = 0; i < items.length; i++) {
    const { title, children } = items[i];

    if (children) {
      const child = menu(children);

      list.push(<li className={ styles.list_item } key={ i }><div>{ title }</div><ul>{ child }</ul></li>);
    } else {
      list.push(<li className={ styles.list_item } key={ i }><div>{ title }</div></li>);
    }
  }

  return list;
}

export const ShortList = ({ list, limit = 5 }: Props) => {
  const [view, setView] = useState(false);
  const [items, setItems] = useState(list.slice(0, limit));
  const size = list.length;

  const handleViewAllItems = () => {
    if (view) {
      setItems(list.slice(0, limit));
      setView(false);
    } else {
      setItems(list);
      setView(true);
    }
  };

  return (
    <React.Fragment>
      <ul className={ styles.list }>
        { menu(items) }
      </ul>
      { size > limit ? (<div className={ styles.list_link } onClick={ handleViewAllItems }>{ view ? 'Скрыть список' : 'Показать весь список' }</div>) : null }
    </React.Fragment>
  );
};