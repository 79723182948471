import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { router } from 'routers/router';
import styles from './styles.module.scss';


export const CookieNotify = () => {
  const [cookie, setCookie] = useState(false);

  const onClose = () => {
    setCookie(true);
    localStorage.setItem('cookie', 'cookie');
  };

  useEffect(() => {
    const isCookie = Boolean(localStorage.getItem('cookie')) || false;

    setCookie(isCookie);
  }, []);

  if (!cookie) {
    return (
      <div className={ styles.cookie }>
        <div className={ styles.wrapper }>
          <span>Сайт использует cookie.</span>
          <Link to={ router.cookie.path } className={ styles.link }>Зачем?</Link>
          <div onClick={ onClose } className={ styles.close }><i className='icon-close'></i></div>
        </div>
      </div>
    );
  }

  return null;
};
