export const data = [
  {
    title: 'Пищевая продукция',
    items: [
      {
        title: 'ТР ТС 015/2011',
        desc: 'О безопасности зерна',
        price: 'От 8000 ₽',
      },
      {
        title: 'ТР ТС 021/2011',
        desc: 'О безопасности пищевой продукции',
        price: 'От 8000 ₽',
      },
      {
        title: 'ТР ТС 023/2011',
        desc: 'На соковую продукцию из фруктов и овощей',
        price: 'От 8000 ₽',
      },
      {
        title: 'ТР ТС 024/2011',
        desc: 'На масложировую продукцию',
        price: 'От 8000 ₽',
      },
      {
        title: 'ТР ТС 033/2013',
        desc: 'О безопасности молока и молочной продукции',
        price: 'От 8500 ₽',
      },
      {
        title: 'ТР ТС 034/2013',
        desc: 'О безопасности мяса и мясной продукции',
        price: 'От 8000 ₽',
      },
      {
        title: 'ТР ТС 029/2012',
        desc: 'Требования безопасности пищевых добавок, ароматизаторов и технологических вспомогательных средств',
        price: 'От 8000 ₽',
      },
    ],
  },
  {
    title: 'ЭМС',
    items: [
      {
        title: 'ТР ТС 004/2011',
        desc: 'О безопасности низковольтного оборудования',
        price: 'От 15 000 ₽',
      },
      {
        title: 'ТР ТС 020/2011',
        desc: 'Электромагнитная совместимость технических средств',
        price: 'От 20 000 ₽',
      },
    ],
  },
  {
    title: 'БМО',
    items: [
      {
        title: 'ТР ТС 010/2011',
        desc: 'О безопасности машин и оборудования',
        price: 'От 18 000 ₽',
      },
    ],
  },
  {
    title: 'Одежда',
    items: [
      {
        title: 'ТР ТС 017/2011',
        desc: 'О безопасности продукции легкой промышленности',
        price: 'От 8 000 ₽',
      },
    ],
  },
  {
    title: 'Игрушки',
    items: [
      {
        title: 'ТР ТС 008/2011',
        desc: 'О безопасности игрушек',
        price: 'От 30 000 ₽',
      },
    ],
  },
  {
    title: 'Дети и подростки',
    items: [
      {
        title: 'ТР ТС 007/2011',
        desc: 'О безопасности продукции, предназначенной для детей и подростков',
        price: 'От 8 500 ₽',
      },
    ],
  },
  {
    title: 'СИЗ',
    items: [
      {
        title: 'ТР ТС 019/2011',
        desc: 'О безопасности средств индивидуальной защиты',
        price: 'От 18 000 ₽',
      },
    ],
  },
];
