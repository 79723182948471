import React, { useState } from 'react';
import cn from 'classnames';
import { SuccessfullySent } from 'components/modal';
import { Input, TextArea } from 'components/ui/form';
import Modal from 'components/ui/modal';
import { eventYm } from 'components/ui/yandex-metrika';
import { schema } from 'form-helpers/reviews/schema';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { createReview, selectReviewsLoading } from 'store/reviews';
import { TReviewModal } from 'types/form';
import styles from './styles.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ReviewsModal = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectReviewsLoading);
  const [isFile, setFile] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const modalClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Modal
        open={ open }
        onCancel={ modalClose }
        className={ styles.modal }
      >
        <div className={ styles.title }>Мой отзыв</div>
        <Formik<TReviewModal>
          initialValues={ {
            authorName: '',
            authorEmail: '',
            desc: '',
          } }
          validationSchema={ schema }
          onSubmit={ (values, { resetForm, setSubmitting }) => {
            dispatch(createReview(values)).unwrap()
              .then(() => {
                setSuccessOpen(true);
                setSubmitting(false);
                resetForm();
                setFile(false);
                eventYm('reachGoal', 'reviews');
                modalClose();
              });
          } }
        >
          { ({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors,
          }) => {
            const handleChangeImage = (event: any) => {
              const file = event.target.value.substr(event.target.value.lastIndexOf('\\') + 1).split('.')[0];

              setFile(file);
              if (event.currentTarget.files) {
                setFieldValue(
                  'image',
                  event.currentTarget.files[0],
                );
              }
            };

            return (
              <form
                className={ styles.form }
                onSubmit={ handleSubmit }
              >
                <Input
                  id="authorName"
                  name='authorName'
                  placeholder="Ваше имя"
                  value={ values.authorName }
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  error={ !!errors.authorName }
                  errorText={ errors.authorName }
                />
                <Input
                  id="authorEmail"
                  name='authorEmail'
                  placeholder="Email"
                  value={ values.authorEmail }
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  error={ !!errors.authorEmail }
                  errorText={ errors.authorEmail }
                />
                <TextArea
                  id="desc"
                  name='desc'
                  placeholder="Напишите ваш отзыв"
                  autoSize={ true }
                  className={ styles.textarea }
                  value={ values.desc }
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  error={ !!errors.desc }
                  errorText={ errors.desc }
                />
                <div className={ styles.formInput }>
                  <div className={ styles.file }>
                    {
                      isFile ?
                        (<div><i className={ cn(styles.file_check, 'icon-check') } /><span className={ styles.file_check }>Фаил выбран</span></div>) :
                        (<div><i className='icon-attach' /><span>Прикрепить файл</span></div>)
                    }
                    <input
                      type="file"
                      id="image"
                      name='image'
                      accept="image/*"
                      className={ styles.file_iput }
                      onChange={ handleChangeImage }
                    />
                  </div>
                  { !!errors.image && <div className={ styles.error }>{ errors.image }</div> }
                </div>
                <button
                  type="submit"
                  disabled={ isSubmitting || loading.create }
                  className={ styles.btn }
                >
                  Оставить отзыв
                </button>
              </form>
            );
          } }
        </Formik>
        <p className={ styles.info }>Нажимая кнопку, вы даете согласие на <b>обработку персональных данных</b></p>
        <p className={ styles.warning }>Перед отправкой отзыва убедитесь, что он не содержит оскорблений и ссылок на сторонние сайты</p>
      </Modal>
      <SuccessfullySent
        open={ successOpen }
        onCancel={ () => setSuccessOpen(false) }
        title="Спасибо! Ваша заявка успешно отправлена, мы <br /> свяжемся с вами в течение 10 мин."
      />
    </React.Fragment>
  );
};