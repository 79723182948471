import { CookieNotify } from 'components/cookie';
import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { Outlet, ScrollRestoration } from 'react-router-dom';

export const Layout = () => (
  <div className="App" >
    <ScrollRestoration />
    <Header />
    <Outlet />
    <Footer />
    <CookieNotify />
  </div>
);