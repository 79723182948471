export const listOfParameters = [
  'Проверка тормозного управления',
  'Проверка шума',
  'Проверка рулевого управления',
  'Проверка обзорности',
  'Проверка выбросов тс',
  'Проверка устройств освещения и световой сигнализации',
  'Проверка шин и колес',
  'Визуальная проверка конструкции и измерение геометрических размеров',
];

export const listOfSubstances = [
  {
    title: 'Транспортные средства категории B (M1, N1)',
  },
];