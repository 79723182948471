import React, { useState } from 'react';
import styles from './styles.module.scss';

type PItem = {
  title: string;
  desc: string;
  price: string;
};

type PropsItem = {
  item: PItem;
};

type Props = {
  child?: PItem[];
  item?: PItem;
  max: number;
};


const Item = ({ item }: PropsItem) => (
  <div className={ styles.item }>
    <div className={ styles.item_content }>
      <div className={ styles.item_title }>{ item.title }</div>
      <div className={ styles.item_desc }>{ item.desc }</div>
    </div>
    <div className={ styles.item_price }>{ item.price }</div>
  </div>
);


export const PriceItem = ({ child, item, max = 100 }: Props) => {

  const [list, setList] = useState(child?.slice(0, max) || []);

  const onNext = () => {
    setList(child || []);
  };
  const onCloseList = () => {
    setList(child?.slice(0, max) || []);
  };

  if (max && child && child.length >= max) {
    const items = list.map((value, i) => <Item item={ value } key={ i } />);

    return (
      <div className={ styles.items }>
        { items }
        { list.length > 3 ? (
          <div
            className={ styles.next }
            onClick={ onCloseList }
          >
            Скрыть
          </div>
        ) : (
          <div
            className={ styles.next }
            onClick={ onNext }
          >
            Показать ещё
          </div>
        ) }
      </div>
    );
  }

  if (child) {
    const items = list.map((value, i) => <Item item={ value } key={ i } />);

    return <div className={ styles.items }>{ items }</div>;
  }

  if (item) {
    return <Item item={ item } />;
  }

  return <div />;
};
