import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import certificatesSlice from 'store/certificates';
import accreditationSlice from './accreditation';
import emailCalculateCostTermsSlice from './calculateCostTerms';
import emailFeedbackSlice from './feedback';
import geoSlice from './geo';
import newsSlice from './news';
import promotionsDiscountSlice from './promotionsDiscount';
import emailQuestionToExpertSlice from './questionToExpert';
import emailQuizSlice from './quiz';
import emailRequestPhoneСallSlice from './requestPhoneСall';
import reviewsSlice from './reviews';

// const sagaMiddleware = createSagaMiddleware();
// const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
});


export const store = configureStore({
  reducer: {
    geo: geoSlice,
    certificates: certificatesSlice,
    news: newsSlice,
    accreditation: accreditationSlice,
    reviews: reviewsSlice,
    promotionsDiscount: promotionsDiscountSlice,
    emailRequestPhoneСall: emailRequestPhoneСallSlice,
    emailQuestionToExpert: emailQuestionToExpertSlice,
    emailCalculateCostTerms: emailCalculateCostTermsSlice,
    emailQuiz: emailQuizSlice,
    emailfeedback: emailFeedbackSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware,
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;