import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  img?: string;
  value: string;
  defaultValue: string;
  onClick: () => void;
};

export const QuizVariant = ({ img, value, defaultValue, onClick }: Props) => {
  const isActive = (value === defaultValue);

  const styleVariant = classNames({
    [styles.variant]: true,
    [styles.variant_noImg]: !img,
  });

  const styleRadio = classNames({
    [styles.radio]: true,
    [styles.active]: isActive,
  });

  return (
    <div className={ styleVariant } onClick={ onClick }>
      { img && (<img src={ img } className={ styles.img } alt="" />) }
      <div className={ styles.value }>
        <span>{ defaultValue }</span>
      </div>
      <div className={ styleRadio } />
    </div>
  );
};
