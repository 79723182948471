import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import * as Api from 'types/api';

export const SendCalculateCostTerms = createAsyncThunk('calculateCostTerms/SendCalculateCostTerms', async (payload: Api.SendCalculateCostTerms) => {
  const response = await fetchers.sendCalculateCostTerms(payload);

  return response.data;
},
);

// Define a type for the slice state
interface AppState {
  loading: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: false,
};

export const emailCalculateCostTermsSlice = createSlice({
  name: 'calculateCostTerms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SendCalculateCostTerms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SendCalculateCostTerms.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(SendCalculateCostTerms.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectEmailCalculateCostTermsSliceLoading = (state: RootState) => state.emailCalculateCostTerms.loading;

export default emailCalculateCostTermsSlice.reducer;