import { useEffect } from 'react';
import cn from 'classnames';
import { Button } from 'components/ui/button';
import { MyImage } from 'components/ui/image';
import Link from 'components/ui/link';
import { ReviewsImageSkeleton, ReviewsTextSkeleton } from 'components/ui/skeleton';
import { useSelector } from 'react-redux';
import { router } from 'routers/router';
import { useAppDispatch } from 'store/hooks';
import {
  clearReviews, getReviewsImage,
  getReviewsText, selectReviewsImage,
  selectReviewsLoading, selectReviewsText,
} from 'store/reviews';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './styles.module.scss';

const Reviews = () => {
  const dispatch = useAppDispatch();
  const reviewsImage = useSelector(selectReviewsImage);
  const reviewsText = useSelector(selectReviewsText);
  const loading = useSelector(selectReviewsLoading);

  useEffect(() => {
    dispatch(getReviewsText({ pagination: { page: 1, size: 4 } }));
    dispatch(getReviewsImage({ pagination: { page: 1, size: 4 } }));

    return () => {
      dispatch(clearReviews());
    };
  }, [dispatch]);

  return (
    <div className={ styles.background }>
      <div className="container">
        <div className="columns">
          <div className={ cn([styles.heading, 'heading']) }>
            <h2>Отзывы наших клиентов</h2>
            <p className="heading_description">Непрерывно улучшаем наш сервис. Поделитесь вашим отзывом с нами. <br /> Помогите нам стать лучше для вас</p>
            <Link href={ router.reviews.path }>
              <Button color="darken" ttu={ true }>Показать все отзывы</Button>
            </Link>
          </div>
        </div>
        <div className={ styles.container }>
          <div className={ styles.slider }>
            <div className={ styles.navigation }>
              <div className={ styles.navigationPrev }><i className='icon-angle-left' /></div>
              <div className={ styles.navigationNext }><i className='icon-angle-right' /></div>
            </div>
            <Swiper
              className={ styles.swiper }
              modules={ [Navigation] }
              spaceBetween={ 20 }
              slidesPerView={ 1 }
              navigation={ {
                prevEl: `.${styles.navigationPrev}`,
                nextEl: `.${styles.navigationNext}`,
              } }
              breakpoints={ {
                481: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 1,
                },
                1001: {
                  spaceBetween: 20,
                  slidesPerView: 2,
                },
              } }
            >
              { loading.getImage ?
                Array(4).fill(null).map((_, i) => (
                  <SwiperSlide key={ i } className={ styles.swiperSlide }>
                    <ReviewsImageSkeleton />
                  </SwiperSlide>
                ),
                ) : (
                  reviewsImage && reviewsImage.map((item, i) => (
                    <SwiperSlide key={ i } className={ styles.swiperSlide }>
                      <MyImage
                        src={ item.image }
                        width='289px'
                        height='412px'
                        alt={ item.authorName }
                        zoom={ true }
                      />
                    </SwiperSlide>
                  ))
                ) }
            </Swiper>
          </div>
          <div className={ styles.block } itemProp="review" itemScope={ true } itemType="http://schema.org/Review">
            { loading.getImage ?
              Array(4).fill(null).map((_, i) => <ReviewsTextSkeleton key={ i } />,
              ) : (
                reviewsText?.map((item) => (
                  <div className={ styles.reviewsText } key={ item.id } itemProp="reviewRating" itemScope={ true } itemType="http://schema.org/Rating">
                    <p itemProp="reviewBody">{ item.desc }</p>
                    <p itemProp="author" itemScope={ true } itemType="http://schema.org/Person">{ item.authorName }</p>
                  </div>
                ))
              ) }
            <Link href={ router.reviews.path }>Посмотреть все отзывы</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
