import { useState } from 'react';
import { QuestionToExpertModal } from 'components/questionToExpert';
import { MyImage } from 'components/ui/image';
import { TCertificates } from 'types/certificates';
import styles from './styles.module.scss';

type Props = {
  data: TCertificates;
};

const DocumentsForConfirmation = ({ data }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={ styles.card }>
      <div className={ styles.header }>
        <div className={ styles.headerContent }>
          <MyImage
            src={ data.image }
            width='100%'
            alt=''
            zoom={ true }
            itemProp="image"
          />
          { data.label && <div className={ styles.label }>снижение цены</div> }
        </div>
      </div>
      <div className={ styles.content }>
        <div className={ styles.duration }>{ data.duration }</div>
        <div className={ styles.title } itemProp="name">{ data.title }</div>
        { /* <div className={ styles.price } itemProp="offers price" itemScope={ true } itemType="https://schema.org/Offer" >от { data.price } ₽</div> */ }
        <div className={ styles.description } itemProp="description">{ data.description }</div>
        <div onClick={ () => setOpen(true) } className={ styles.link }>Рассчитать стоимость</div>
        <QuestionToExpertModal open={ open } onClose={ () => setOpen(false) } />
      </div>
    </div>
  );
};

export default DocumentsForConfirmation;
