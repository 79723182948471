import React, { useState } from 'react';
import cn from 'classnames';
import { City } from 'components/city';
import { RequestPhoneСallModal } from 'components/form';
import { CompanyInfo } from 'components/ui/companyInfo';
import { Hamburger } from 'components/ui/menu/hamburger';
import { MobileMenu } from 'components/ui/menu/mobile';
import { SldierMenu } from 'components/ui/menu/slider';
import { Search } from 'components/ui/search';
import { Link } from 'react-router-dom';
import { router, routerHeader, routerHeaderTop } from 'routers/router';
import imgLogo from 'assets/images/imgLogo.svg';
import telegram from 'assets/images/telegram.png';
import textLogo from 'assets/images/textLogo.svg';
import viber from 'assets/images/viber.png';
import whatsApp from 'assets/images/whatsApp.png';
import styles from './styles.module.scss';

export const Header = React.memo(() => {
  const [toggle, setToggle] = useState(false);
  const [openPhone, setoOpenPhone] = useState(false);
  const width = document.body.clientWidth;
  const isMobile = width <= 767;

  const onToggle = () => {
    setToggle(!toggle);

    if (toggle) {
      document.body.removeAttribute('style');
    } else {
      document.body.style.overflow = 'hidden';
    }
  };

  const stylesMenu = cn({
    [styles.menu]: !isMobile,
    [styles.mobile_menu]: isMobile,
    [styles.mobile_menu__open]: toggle,
  });

  const copmany = [{
    title: 'Бесплатно по России',
    phone: '8 (800) 777-61-58',
    link: {
      title: 'info@delta-certificate.com',
      href: 'mailto:info@delta-certificate.com',
    },
  }, {
    title: 'с 9:00-18:00',
    phone: '8 (495) 108-32-91',
    link: {
      title: 'Заказать обратный звонок',
      href: '#',
      onClick: () => setoOpenPhone(true),
    },
  }];

  return (
    <div className={ styles.header }>
      <div className={ cn(styles.top, 'container') }>
        <div className={ cn(styles.top_columns, 'row') }>
          <ul className={ styles.list }>
            { routerHeaderTop.map((item, i) => (
              <li key={ i }><Link to={ item.path }>{ item.name }</Link></li>
            )) }
          </ul>
          <div className={ styles.top_empty }></div>
          <Search />
        </div>
      </div>
      <div className={ cn(styles.middle, 'container') }>
        <div className={ styles.middle_content }>
          <Hamburger onToggle={ onToggle } toggle={ toggle } />
          <Link
            to={ router.main.path }
            className={ styles.logo }
            itemScope={ true }
            itemType="https://schema.org/ImageObject"
          >
            <img src={ imgLogo } className={ styles.logo__img } alt="Логотип" itemProp="url image logo" />
            <img src={ textLogo } className={ styles.logo__txt } alt="Логотип" itemProp="url image logo" />
          </Link>
          <City />
          <div className={ styles.social }>
            <a href="https://wa.me/+79176040396" target='_blank'><img src={ whatsApp } alt="" /></a>
            <a href="tg://resolve?domain=Delta_certificate" target='_blank'><img src={ telegram } alt="" /></a>
            <a href="viber://chat?number=+79176040396" target='_blank'><img src={ viber } alt="" /></a>
          </div>
          <div className={ styles.space } />
          <a className={ styles.mobile_phone } href='tel:8 (800) 777-61-58'>8 (800) 777-61-58</a>
          <CompanyInfo data={ copmany } />
        </div>
      </div>
      <div className={ stylesMenu }>
        <div className="container">
          <div className="row">
            { isMobile ? (
              <MobileMenu menu={ routerHeader } />
            ) : (
              <SldierMenu menu={ routerHeader } />
            ) }
          </div>
        </div>
      </div>
      <RequestPhoneСallModal open={ openPhone } onClose={ () => setoOpenPhone(false) } />
    </div>
  );
});
