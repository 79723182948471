import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Svg } from 'components/ui/svg';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styles from './styles.module.scss';

const appRoot = document.getElementById('root') as HTMLElement;

type Props = {
  open: boolean;
  onCancel: () => any;
  children: React.ReactNode;
  className?: string;
};

const Modal: React.FC<Props> = (props) => {
  const refModal = useRef<any>();

  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onCancel();
    }
  };

  const handleClickOutside = (e: any) => {
    if (refModal.current && !refModal.current.contains(e.target)) {
      props.onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={ props.open }
      unmountOnExit={ true }
      timeout={ 300 }

    >
      <div className={ styles.modal__overlow }>
        <div
          ref={ refModal }
          className={ classNames(styles.modal, props.className) }
        >
          <div className={ styles.modal__close } onClick={ () => props.onCancel() }>
            <Svg className={ styles.close } name='close' />
          </div>
          { props.children }
        </div>
      </div>
    </CSSTransition>,
    appRoot,
  );
};

export default Modal;
