import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  list: Array<{
    title: string;
    description: string;
  }>;
  className?: string;
};

export const ListPlusWhite: FC<Props> = ({ list, className }) => (
  <ul className={ classNames(styles.marker, className) }>
    { list.map((item, i) => (
      <li key={ i }>
        <div className={ styles.title }>{ item.title }</div>
        <p className={ styles.desc }>{ item.description }</p>
      </li>
    )) }
  </ul>
);