import React from 'react';
import cl from './style.module.scss';

type TInfo = {
  title: string;
  phone: string;
  link: {
    title: string;
    href?: string;
    onClick?: () => void;
  };
};
type Props = {
  data: TInfo[];
};

export const CompanyInfo = ({ data }: Props) => {
  if (!data.length) {
    return <div />;
  }

  const info = data.map((item, i) => (
    <div className={ cl.companyInfo } key={ i }>
      <div className={ cl.companyInfo__title }>{ item.title }</div>
      <a className={ cl.companyInfo__phone } href={ `tel:${item.phone}` }>{ item.phone }</a>
      <a
        className={ cl.companyInfo__link }
        href={ item.link.href }
        onClick={ item.link.onClick }
      >
        { item.link.title }
      </a>
    </div>
  ));

  return <div className={ cl.companyInfo_list }>{ info }</div>;
};