import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  list: string[];
  className?: string;
};

export const ListPlus: FC<Props> = ({ list, className }) => (
  <ul className={ classNames(styles.marker, className) }>
    { list.map((item, i) => <li key={ i }>{ item }</li>) }
  </ul>
);