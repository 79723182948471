import { Layout } from 'components/ui/Layout';
import { PageNotFound } from 'pages/notFound';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { router } from './router';

export const BaseRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={ <Layout /> } errorElement={ <PageNotFound /> }>
      <Route index={ true } { ...router.main } />
      <Route { ...router.privacy } />
      <Route { ...router.person } />
      <Route { ...router.cookie } />
      <Route { ...router.accreditation } />
      <Route { ...router.delivery } />
      <Route { ...router.services } />
      <Route { ...router.reviews } />
      <Route { ...router.contacts } />
      <Route { ...router.company } />
      <Route { ...router.iso } />
      <Route { ...router.haccp } />
      <Route { ...router['certification-of-conformity'] } />
      <Route { ...router['declaration-of-conformity'] } />
      <Route { ...router['test-report'] } />
      <Route { ...router['rejection-letter'] } />
      <Route { ...router['fire-certificate'] } />
      <Route { ...router['technical-conditions'] } />
      <Route { ...router['certificate-of-origin'] } />
      <Route { ...router['certificate-of-state-organization'] } />
      <Route { ...router['chemical-laboratory'] } />
      <Route { ...router['equipment-laboratory'] } />
      <Route { ...router['mechanics-laboratory'] } />
      <Route { ...router['furniture-laboratory'] } />
      <Route { ...router['wheel-laboratory'] } />
    </Route>,
  ),
);