import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import { ICityItem, IGeo, IGeoCurrent } from 'types/geo';

export const getGeo = createAsyncThunk('geo/getGeo', async () => {
  const response = await fetchers.getGeo();

  return response.data;
},
);

// Define a type for the slice state
interface AppState {
  loading: {
    get: boolean;
  };
  current: IGeoCurrent | null;
  geo: IGeo | null;
  city: ICityItem[] | null;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: {
    get: false,
  },
  current: null,
  geo: null,
  city: null,
};

export const geoSlice = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    clearGeo: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getGeo.pending, (state) => {
      state.loading.get = true;
    });
    builder.addCase(getGeo.rejected, (state) => {
      state.loading.get = false;
    });
    builder.addCase(getGeo.fulfilled, (state, action) => {
      state.loading.get = false;
      state.current = action.payload.current;
      state.geo = action.payload.geo;
      state.city = action.payload.city;
    });
  },
});

export const { clearGeo } = geoSlice.actions;
export const selectGeoCity = (state: RootState) => state.geo.city;
export const selectGeoCurrent = (state: RootState) => state.geo.current;
export const selectGeo = (state: RootState) => state.geo.geo;
export const selectGeoLoading = (state: RootState) => state;

export default geoSlice.reducer;