import { MyImage } from 'components/ui/image';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
  image: string;
  title: string;
  localUrl: any;
};

export const ServiceItem = ({ image, title, localUrl = 'main' }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={ styles.service }
      itemProp="hasOfferCatalog"
      itemScope={ true }
      itemType="https://schema.org/OfferCatalog"
      onClick={ localUrl ? () => navigate(localUrl) : ()=>{} }
    >
      <MyImage
        src={ image }
        width="100%"
        alt={ title }
        className={ styles.image }
        itemProp="contentUrl"
        itemScope={ true }
        itemType="https://schema.org/ImageObject"
      />
      <div className={ styles.service_content } itemProp="itemOffered" itemScope={ true } itemType="https://schema.org/Service">
        <div className={ styles.service__name } itemProp="name">{ title }</div>
        <div className={ styles.service__go }>
          <i className='icon-angleright'></i>
        </div>
      </div>
    </div>
  );
};
