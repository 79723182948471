import { useEffect } from 'react';
import cn from 'classnames';
import { ServiceSkeleton } from 'components/ui/skeleton';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { getNews, selectNews, selectNewsLoading } from 'store/news';
import { A11y, EffectFade, Navigation } from 'swiper';
// eslint-disable-next-line import/order
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { ServiceItem } from './item';
import styles from './styles.module.scss';


export const NewsSlider = () => {
  const uid = Date.now();
  const dispatch = useAppDispatch();
  const news = useSelector(selectNews);
  const loading = useSelector(selectNewsLoading);

  useEffect(() => {
    dispatch(getNews({ pagination: { page: 1 } }));
  }, [dispatch]);

  return (
    <div className={ cn(styles.service, 'section-mb') }>
      <div className="container">
        <div className="flex-row-column">
          <div className={ styles.navigation }>
            <div className={ cn(styles.navigation__prev, `service_swiper_${uid}_prev`) }><i className='icon-angle-left' /></div>
            <div className={ cn(styles.navigation__next, `service_swiper_${uid}_next`) }><i className='icon-angle-right' /></div>
          </div>
          <Swiper
            className={ styles.swiper }
            modules={ [Navigation, A11y, EffectFade] }
            navigation={ {
              prevEl: `.service_swiper_${uid}_prev`,
              nextEl: `.service_swiper_${uid}_next`,
            } }
            coverflowEffect={ {
              rotate: 20,
              stretch: 25,
              depth: 250,
              modifier: 1,
              slideShadows: false,
            } }
            breakpoints={ {
              1001: {
                spaceBetween: 20,
                slidesPerView: 4,
              },
              960: {
                spaceBetween: 20,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 20,
                slidesPerView: 3,
              },
              480: {
                spaceBetween: 20,
                slidesPerView: 2,
              },
              300: {
                spaceBetween: 0,
                slidesPerView: 1,
              },
            } }
          >
            { loading.get ? Array(4).fill(null).map((_, i) => (
              <SwiperSlide key={ i } className={ styles.swiperSlider }>
                <ServiceSkeleton />
              </SwiperSlide>
            )) : (
              news && news.map((item, i) => (
                <SwiperSlide key={ i }>
                  <ServiceItem
                    image={ item.image }
                    title={ item.title }
                    localUrl={ item.localUrl }
                  />
                </SwiperSlide>
              ))
            ) }
          </Swiper>
        </div>
      </div>
    </div>
  );
};
