import React from 'react';
import cl from './styles.module.scss';

type Props = {
  item: {
    img: string;
    title: string;
    describtion?: string;
  };
};

export const ServicesMiniItem = ({ item }: Props) => (
  <div className={ cl.item }>
    <div className={ cl.image }>
      <img src={ item.img } alt="" />
    </div>
    <div className={ cl.contant }>
      <div className={ cl.title }>
        { item.title }
      </div>
      { item.describtion && (
        <div className={ cl.desc }>
          { item.describtion }
        </div>
      ) }
    </div>
  </div>
);

