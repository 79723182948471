import axios from 'axios';
import { ContentTypes, getBody } from 'form-helpers/utils';
import * as Api from 'types/api';

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';

export const fetchers = {
  // получение Дельта-Сертификат — это центр сертификации
  getNews: ({ pagination }: Api.GetNewsPayload) => axios.get('/api/news', {
    params: { ...pagination },
  }),
  // получение Предоставляем все документы для подтверждения соответсвия
  getСertificates: ({ pagination }: Api.GetCertificatesPayload) => axios.get('/api/certificates', {
    params: { ...pagination },
  }),
  // Аттестаты аккредитации
  getAccreditation: ({ pagination }: Api.GetAccreditationPayload) => axios.get('/api/accreditation', {
    params: { ...pagination },
  }),
  // Акции и скидки
  getPromotionsDiscount: ({ pagination }: Api.GetAccreditationPayload) => axios.get('/api/promotions-discounts', {
    params: { ...pagination },
  }),
  // получаем отзывы
  getReviews: ({ variant, pagination }: Api.GetReviewsPayload) => axios.get(`/api/reviews/${variant}`, {
    params: { ...pagination },
  }),
  createReview: (data: Api.CreateReviews) => axios.post('/api/reviews', getBody(
    data,
    ContentTypes.MPFD,
  ), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),

  // получаем атестаты
  getGeo: () => axios.get('/api/geo'),
  // Отправляем сообщение обратной связи
  sendRequestPhoneСall: (data: Api.SendRequestPhoneСallPayload) => axios.post('https://deltasert.ru/api/promorequests', data),
  sendQuestionToExpert: (data: Api.SendQuestionToExpertPayload) => axios.post('https://deltasert.ru/api/promorequests', data),
  sendCalculateCostTerms: (data: Api.SendCalculateCostTerms) => axios.post('https://deltasert.ru/api/promorequests', data),
  sendQuiz: (data: Api.SendQuiz) => axios.post('https://deltasert.ru/api/promorequests', data),
  sendFeedback: (data: Api.SendFeedback) => axios.post('https://deltasert.ru/api/promorequests', data),
};
