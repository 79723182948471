import React from 'react';
import classNames from 'classnames';
import cl from './style.module.scss';


interface Props {
  children: React.ReactNode;
  className?: string;
  ttu?: boolean;
  color: 'darken' | 'red' | 'redDarken';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: React.FC<Props> = ({ children, ttu, color, className, ...props }) => {
  const style = classNames({
    [cl.button]: true,
    [cl.ttu]: ttu,
    [cl[color]]: true,
  }, className);

  return (
    <button className={ style } { ...props }>{ children }</button>
  );
};