import moment from 'moment';
import 'moment/locale/ru';
import 'react-medium-image-zoom/dist/styles.css';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { BaseRouter } from 'routers';
import { store } from 'store';


moment.locale('ru');


export const App = () => (
  <Provider store={ store }>
    <RouterProvider router={ BaseRouter } />
  </Provider>
);
export default App;
