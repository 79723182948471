import React from 'react';
import classNames from 'classnames';
import type { InputProps as RcInputProps } from 'rc-input';
import styles from './styles.module.scss';


// export const Input = (props: InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement> | null) => {

// interface Props extends React.HTMLAttributes {
export interface InputProps extends Omit<RcInputProps, 'wrapperClassName' | 'groupClassName' | 'inputClassName' | 'affixWrapperClassName'> {
  size?: 'xs' | 'md' | 'lg';
  error?: boolean;
  disabled?: boolean;
  errorText?: string;
  [key: `data-${string}`]: string | undefined;
}

export const Input = (props: InputProps) => {
  const prefixCls = 'input';

  return (
    <div className={ styles.formInput }>
      <input
        id={ props.id }
        type={ props.type }
        className={ classNames(
          {
            [styles.input]: true,
            [styles[`${prefixCls}_xs`]]: props.size === 'xs',
            [styles[`${prefixCls}_md`]]: props.size === 'md',
            [styles[`${prefixCls}_lg`]]: props.size === 'lg',
            [styles[`${prefixCls}_error`]]: props.errorText,
          }) }
        onChange={ props.onChange }
        onBlur={ props.onBlur }
        onFocus={ props.onFocus }
        value={ props.value }
        placeholder={ props.placeholder }
      />
      { props.errorText && <div className={ styles.error }>{ props.errorText }</div> }
    </div>
  );
};

Input.defaultProps = {
  type: 'input',
  size: 'md',
  placeholder: 'Ваше значение',
};