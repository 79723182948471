import React, { createRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TSpotlight } from 'interface/search';
import styles from './styles.module.scss';

const pages: TSpotlight = [{
  title: 'Сертификация соответствия',
  description: 'Таможенного союза, Добровольная сертификация',
  path: '/certification-of-conformity',
}, {
  title: 'Декларация соответствия',
  description: 'Декларация ГОСТ Р, Таможенного союза',
  path: '/declaration-of-conformity',
}, {
  title: 'Протоколы испытаний',
  description: 'По всем необходимым параметрам',
  path: '/test-report',
}, {
  title: 'Отказное письмо',
  description: 'Оформление за 1 день',
  path: '/rejection-letter',
}, {
  title: 'Пожарный сертификат',
  description: 'По 123 ФЗ, Добровольный сертификат',
  path: '/fire-certificate',
}, {
  title: 'ISO',
  description: 'ИСО 9001, 14001, ХАССП',
  path: '/iso',
}];

export const Search = React.memo(() => {
  const ref = useRef<any>();
  const refInput = createRef<any>();
  const [spotlight, setSpotlight] = useState(false);
  const [list, setList] = useState(pages);
  const [text, setText] = useState('');

  const placeholder = 'Какая услуга вас интересует?';

  const onFocus = () => {
    setSpotlight(!spotlight);
    if (refInput.current) {
      refInput.current.focus();
      refInput.current.scrollIntoView();
    }
  };

  const onSearch = (val: string) => {
    setText(val);
    const newList = pages.filter((item) => (item.title.toLowerCase().search(val.toLowerCase()) >= 0 || item.description.toLowerCase().search(val.toLowerCase()) >= 0) && item);

    setList(newList);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (spotlight && ref.current && !ref.current.contains(e.target)) {
        setSpotlight(false);
        setText('');
      }
    };

    if (spotlight) {
      document.body.style.cssText = 'overflow:hidden;';
    } else {
      document.body.removeAttribute('style');
    }
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [spotlight]);

  const stylesSpotlight = classNames({
    [styles.spotlight]: true,
    [styles.spotlight_open]: spotlight,
  });

  return (
    <div className={ styles.container }>
      <div className={ styles.search }>
        <input
          type="text"
          placeholder={ placeholder }
          className={ styles.search__input }
          onClick={ onFocus }
        />
        <button
          type='button'
          className={ styles.search__btn }
          onClick={ onFocus }
        >
          <i className='icon-search' />
        </button>
      </div>
      { spotlight && (
        <div className={ stylesSpotlight }>
          <div className={ styles.inner }>
            <div className={ styles.wrapper } ref={ ref }>
              <div className={ styles.spotlight_search }>
                <i className='icon-search' />
                <input
                  ref={ refInput }
                  autoFocus={ true }
                  value={ text }
                  onChange={ (e) => onSearch(e.target.value) }
                  type="text"
                  placeholder={ placeholder }
                  className={ styles.spotlight_input }
                />
              </div>
              <div className={ styles.spotlight_list }>
                { list.map((item, i) => (
                  <a href={ item.path } className={ styles.spotlight_list__item } key={ i }>
                    <div className={ styles.spotlight_list__img }>
                      <i className='icon-angleright' />
                    </div>
                    <div className={ styles.spotlight_list__content }>
                      <div className={ styles.spotlight_list__name }>{ item.title }</div>
                      <div className={ styles.spotlight_list__desc }>{ item.description }</div>
                    </div>
                  </a>
                )) }
              </div>
            </div>
            <div className={ styles.opacity }>
              <div className={ styles.overlay } >
                <div className={ styles.overDark } />
              </div>
            </div>
          </div>
        </div>
      ) }
    </div>
  );
});
