import React from 'react';
import cn from 'classnames';
import { CookieNotify } from 'components/cookie';
import styles from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
  container?: boolean;
  type?: 'row' | 'columns';
  flex?: boolean;
};

export const AppLayout: React.FC<Props> = ({ children, container, flex, type, ...props }) => {

  const styleType = cn({
    [styles.row]: type === 'row',
    [styles.columns]: type === 'columns',
  });

  if (flex) {
    return (
      <div className={ styles.flex } { ...props }>
        <div className={ styleType }>
          { children }
        </div>
        <CookieNotify />
      </div>
    );
  }

  if (container) {
    return (
      <div className='container' >
        <div  { ...props }>
          { children }
        </div>
        <CookieNotify />
      </div>
    );
  }

  return <React.Fragment>{ children }<CookieNotify /></React.Fragment>;
};
