import { FC } from 'react';
import { MyImage } from 'components/ui/image';
import { TAccreditation } from 'types/accreditation';
import styles from './styles.module.scss';

type Props = {
  data: TAccreditation;
};

export const AccreditationBlock: FC<Props> = ({ data }) => (
  <div className={ styles.block }>
    <div className={ styles.imgBlock }>
      <MyImage src={ data.image } width="100%" alt='' className={ styles.img } zoom={ true } />
    </div>
    <div className={ styles.desc }>{ data.descripton }</div>
    <div className={ styles.title }>{ data.title }</div>
    <div className={ styles.info } dangerouslySetInnerHTML={ { __html: data.fullDescripton } } />
    <a href={ data.url } target="_blank" className={ styles.link }>Проверить на сайте ФСА</a>
  </div>
);