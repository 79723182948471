
import { Accreditation } from 'pages/accreditation';
import { AllServices } from 'pages/allServices';
import { CertificateOfOrigin } from 'pages/certificate-of-origin';
import { CertificateOfStateOrganization } from 'pages/certificate-of-state-organization';
import { CertificationOfConformity } from 'pages/certification-of-conformity';
import { ChemicalLaboratory } from 'pages/chemical-laboratory';
import { Company } from 'pages/company';
import { Contacts } from 'pages/contacts';
import { Cookie } from 'pages/cookie';
import { DeclarationOfСonformity } from 'pages/declaration-of-conformity';
import { Delivery } from 'pages/delivery';
import { EquipmentLaboratory } from 'pages/equipment-laboratory';
import { FireCertificate } from 'pages/fire-certificate';
import { FurnitureLaboratory } from 'pages/furniture-laboratory';
import { Haccp } from 'pages/haccp';
import { Iso } from 'pages/iso';
import { Main } from 'pages/main';
import { MechanicsLaboratory } from 'pages/mechanics-laboratory';
import { Person } from 'pages/person';
import { Privacy } from 'pages/privacy';
import { Promotiondiscounts } from 'pages/promotionsDiscounts';
import { RejectionLetter } from 'pages/rejection-letter';
import { Reviews } from 'pages/reviews';
import { TechnicalConditions } from 'pages/technical-conditions';
import { TestReport } from 'pages/test-report';
import { WheelLaboratory } from 'pages/wheel-laboratory';

export const router = {
  main: {
    path: '/',
    name: 'Главная',
    element: <Main />,
  },
  person: {
    path: '/person',
    name: 'Согласие на обработку персональных данных',
    element: <Person />,
  },
  privacy: {
    path: '/privacy',
    name: 'Политика в отношении обработки персональных данных',
    element: <Privacy />,
  },
  cookie: {
    path: '/cookie',
    name: 'Cookie файлы',
    element: <Cookie />,
  },
  delivery: {
    path: '/delivery',
    name: 'Доставка и оплата',
    element: <Delivery />,
  },
  services: {
    path: '/services',
    name: 'Услуги',
    element: <AllServices />,
  },
  accreditation: {
    path: '/accreditation',
    name: 'Аккредитации',
    element: <Accreditation />,
  },
  reviews: {
    path: '/reviews',
    name: 'Отзывы',
    element: <Reviews />,
  },
  promotionsdiscounts: {
    path: '/promotions-and-discounts',
    name: 'Акции и скидки',
    element: <Promotiondiscounts />,
  },
  company: {
    path: '/company',
    name: 'О компании',
    element: <Company />,
  },
  contacts: {
    path: '/contacts',
    name: 'Контакты',
    element: <Contacts />,
  },
  'certification-of-conformity': {
    path: '/certification-of-conformity',
    name: 'Сертификация соответствия',
    element: <CertificationOfConformity />,
  },
  'declaration-of-conformity': {
    path: '/declaration-of-conformity',
    name: 'Декларация соответствия',
    element: <DeclarationOfСonformity />,
  },
  'test-report': {
    path: '/test-report',
    name: 'Протоколы испытаний',
    element: <TestReport />,
  },
  'rejection-letter': {
    path: '/rejection-letter',
    name: 'Отказное письмо',
    element: <RejectionLetter />,
  },
  'fire-certificate': {
    path: '/fire-certificate',
    name: 'Пожарный сертификат',
    element: <FireCertificate />,
  },
  iso: {
    path: '/iso',
    name: 'ISO',
    element: <Iso />,
  },
  haccp: {
    path: '/haccp',
    name: 'ХАССП',
    element: <Haccp />,
  },
  'technical-conditions': {
    path: '/technical-conditions',
    name: 'Технические условия',
    element: <TechnicalConditions />,
  },
  'certificate-of-origin': {
    path: '/certificate-of-origin',
    name: 'Сертификат происхождения',
    element: <CertificateOfOrigin />,
  },
  'certificate-of-state-organization': {
    path: '/certificate-of-state-organization',
    name: 'СГР',
    element: <CertificateOfStateOrganization />,
  },
  'chemical-laboratory': {
    path: '/chemical-laboratory',
    name: 'Химическая лаборатория',
    element: <ChemicalLaboratory />,
  },
  'equipment-laboratory': {
    path: '/equipment-laboratory',
    name: 'Лаборатория электроиспытаний',
    element: <EquipmentLaboratory />,
  },
  'mechanics-laboratory': {
    path: '/mechanics-laboratory',
    name: 'Лаборатория электроиспытаний',
    element: <MechanicsLaboratory />,
  },
  'furniture-laboratory': {
    path: '/furniture-laboratory',
    name: 'Лаборатория электроиспытаний',
    element: <FurnitureLaboratory />,
  },
  'wheel-laboratory': {
    path: '/wheel-laboratory',
    name: 'Лаборатория электроиспытаний',
    element: <WheelLaboratory />,
  },
};

export const navigateRouter = () => {
  const arr: any = [];

  for (const key of Object.entries(router)) {
    const item = key[1];

    arr[item.path] = item.name;
  }

  return arr;
};


export const routerHeaderTop = [
  { ...router.accreditation },
  { ...router.delivery },
  { ...router.reviews },
  // { ...router.promotionsdiscounts },
  { ...router.company },
  { ...router.contacts },
];

export const routerHeader = [
  { ...router['certification-of-conformity'] },
  { ...router['declaration-of-conformity'] },
  { ...router['test-report'] },
  { ...router['rejection-letter'] },
  { ...router['fire-certificate'] },
  { ...router.iso },
  { ...router.haccp },
  { ...router['technical-conditions'] },
  { ...router['certificate-of-origin'] },
  { ...router['certificate-of-state-organization'] },
];

export const routerFooter = [
  { ...router.company },
  { ...router.contacts },
  { ...router.accreditation },
  { ...router.delivery },
  { ...router.reviews },
  // { ...router.promotionsdiscounts },
];
