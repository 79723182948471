import cn from 'classnames';
import { AppLayout, AppNavigation } from 'components/ui/app';
import { MyImage } from 'components/ui/image';
import paySystems from 'assets/images/pay-systems.jpg';
import styles from './styles.module.scss';

export const Delivery = () => {
  return (
    <AppLayout flex={ true }>
      <AppNavigation />
      <h2 className={ styles.heading }>Доставка</h2>
      <p className={ styles.pmb15 }>Доставка документов “Почтой России”  — <b>БЕСПЛАТНО</b>.</p>
      <p className={ styles.pmb15 }>Курьерская доставка “Major”   —  бесплатно, при сумме доставки ниже 1000 ₽. </p>
      <p className={ styles.pmb15 }>Точную стоимость, сроки и доступные способы доставки вы можете уточнить у менеджера по телефону <b>8 (800) 777-61-58</b> или написать в чате на сайте.</p>

      <h2 className={ cn(styles.heading, styles['heading--top']) }>Оплата</h2>
      <MyImage src={ paySystems } width='186px' alt='' className={ styles.pmb30 } />
      <p className={ styles.pmb15 }>Оплата картой, банковский перевод, безналичная оплата</p>
      <p className='section-mb'>100% предоплата документов по расчетному счету</p>
    </AppLayout>
  );
};
