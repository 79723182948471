export const listOfParameters = [
  'Измерение работоспособности',
  'Измерение прогиба',
  'Измерение долговечности',
  'Измерение сопротивления ударным нагрузкам',
  'Измерение устойчивости',
  'Испытания при динамических и статических нагрузках',
  'Измерение усилиий',
  'Измерение механической прочности',
  'Измерение жесткости',
];

export const listOfSubstances = [
  {
    title: 'МЕБЕЛЬ БЫТОВАЯ И ДЛЯ ОБЩЕСТВЕННЫХ ПОМЕЩЕНИЙ ПО ЭКСПЛУАТАЦИОННОМУ НАЗНАЧЕНИЮ:',
    children: [
      { title: 'Мебель лабораторная, за исключением мебели медицинской лабораторной;' },
      { title: 'Мебель для учебных заведений;' },
      { title: 'Мебель для дошкольных учреждений;' },
      { title: 'Мебель для предприятий торговли, общественного питания и бытового обслуживания;' },
      { title: 'Мебель для гостиниц, здравниц и общежитий;' },
      { title: 'Мебель для театрально-зрелищных предприятий и учреждений культуры;' },
      { title: 'Мебель для административных помещений;' },
      { title: 'Мебель для вокзалов, финансовых учреждений и предприятий связи;' },
      { title: 'Мебель для книготорговых помещений (стеллажи, полки, шкафы, столы);' },
      { title: 'Мебель для судовых помещений (кают, кают-компаний, ходового мостика, центрального поста управления, помещений для отдыха, столовой, амбулатории);' },
    ],
  },
  {
    title: 'МЕБЕЛЬ БЫТОВАЯ И ДЛЯ ОБЩЕСТВЕННЫХ ПОМЕЩЕНИЙ ПО ФУНКЦИОНАЛЬНОМУ НАЗНАЧЕНИЮ:',
    children: [
      { title: 'Столы (обеденные, письменные, туалетные, журнальные, компьютерные, столы для теле-, радио, видеоаппаратуры, для телефонов, для террас и прихожих и другие изделия);' },
      { title: 'Стулья, кресла, банкетки, табуреты, скамьи, пуфы, диваны, кушетки, тахты, кресла-кровати, диван-кровати;' },
      { title: 'Кровати;' },
      { title: 'Матрацы;' },
      { title: 'Шкафы, тумбы, стеллажи, секретеры, комоды, трюмо, трельяжи и прочие изделия (сундуки, этажерки, ширмы, подставки, вешалки, ящики, полки и другие изделия);' },
      { title: 'Мебель детская (столы, стулья, кровати, манежи, ящики для игрушек, кресла, диваны, шкафы, тумбы, табуреты, скамьи, матрацы, диван-кровати и другие изделия);' },
    ],
  },
];