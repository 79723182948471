import styles from './styles.module.scss';

type Props = {
  href: string;
  children: React.ReactNode;
};

const Link = ({ href, children }: Props) => (
  <a href={ href } className={ styles.link }>
    { children }
  </a>
);

export default Link;
