import cn from 'classnames';
import Bookmark from 'components/ui/bookmark';
import styles from './styles.module.scss';

type Props = {
  rootClass?: string;
  className?: string;
};

const Confidence = ({ rootClass, className }: Props) => {
  return (
    <div className={ cn(rootClass) }>
      <div className={ cn('container', className) }>
        <div className="columns">
          <div className={ cn([styles.heading, 'heading']) }>
            <Bookmark>доверяют лучшим</Bookmark>
            <h2>Почему нам доверяют</h2>
          </div>
          <div className={ styles.list }>
            <div className={ styles.item }>
              <i className={ cn(['icon-plus', styles.icon]) }></i>
              <div className={ styles.content }>
                <div className={ styles.title }>Аккредитованные органы</div>
                <p className={ styles.description }>И испытательные лаборатории с широкими областями аккредитации. Официальная выгрузка в реестр ФСА за 24 часа</p>
              </div>
            </div>
            <div className={ styles.item }>
              <i className={ cn(['icon-plus', styles.icon]) }></i>
              <div className={ styles.content }>
                <div className={ styles.title }>Поддержка эксперта</div>
                <p className={ styles.description }>Задавайте вопросы профессиональным эксператм в мессенджерах или в чате на сайте и получайте быстрый ответ </p>
              </div>
            </div>
            <div className={ styles.item }>
              <i className={ cn(['icon-plus', styles.icon, styles['icon--red']]) }></i>
              <div className={ styles.content }>
                <div className={ styles.title }>Быстрые сроки</div>
                <p className={ styles.description }>Макеты и сканы документов вышлем моментально после готовности. Подбор кодов ТНВЭД. Штат более 50 сотрудников</p>
              </div>
            </div>
            <div className={ styles.item }>
              <i className={ cn(['icon-plus', styles.icon]) }></i>
              <div className={ styles.content }>
                <div className={ styles.title }>Низкие цены сертификации</div>
                <p className={ styles.description }>Работаем без посредников. Индивидуальные предложения каждому клиенту. Бесплатная доставка документов в любую точку России</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confidence;
