import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import type { PayloadAction } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import * as Api from 'types/api';
import { TCertificates } from 'types/certificates';
import { Pagination, Sort } from 'types/pagination';

export const getCertificates = createAsyncThunk('certificates/getCertificates',
  async (payload: Api.GetCertificatesPayload, { getState }) => {
    const { certificates } = getState() as RootState;

    const response = await fetchers.getСertificates({
      pagination: {
        ...payload.pagination,
        sortOrder: certificates.pagination.sortOrder,
      },
    });

    return response.data;
  },
);

// Define a type for the slice state
interface AppState {
  loading: {
    get: boolean;
  };
  items: TCertificates[] | null;
  pagination: Pagination & Sort;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: {
    get: false,
  },
  items: null,
  pagination: {
    page: 1,
    size: 8,
    itemCount: 0,
    pageSize: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    sortField: 'createdAt',
    sortOrder: 'asc',
  },
};

export const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    clearCertificates: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCertificates.pending, (state) => {
      state.loading.get = true;
    });
    builder.addCase(getCertificates.rejected, (state) => {
      state.loading.get = false;
    });
    builder.addCase(getCertificates.fulfilled, (state, action) => {
      state.loading.get = false;
      state.items = action.payload.data;
      state.pagination = action.payload.pagination;
    });
  },
});

// export const { setLoading, getService, setService } = serviceSlice.actions;
export const selectCertificateHome = (state: RootState) => state.certificates.items;
export const selectCertificates = (state: RootState) => state.certificates.items;
export const selectCertificatePagination = (state: RootState) => state.certificates.pagination;
export const selectCertificateLoading = (state: RootState) => state.certificates.loading;

export default certificatesSlice.reducer;