import React from 'react';
import cn from 'classnames';
import { A11y, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { data } from './data';
import cl from './styles.module.scss';
import { ServicesMiniItem } from '../mini';


export const ServicesMain = () => {
  const swiperId = 'swiper_sercicesmini';

  return (
    <React.Fragment>
      <div className="container">
        <div className="flex-row-column">
          <div className="heading m600">
            <h2>Услуги</h2>
            <p className='heading_description'>В состав Дельты входит испытательный центр <br /> с рядом профильных лабораторий</p>
          </div>
        </div>
      </div>
      <div className={ cl.services }>
        <Swiper
          id={ swiperId }
          className={ cl.swiper }
          modules={ [Navigation, A11y, Autoplay] }
          spaceBetween={ 30 }
          slidesPerView={ 'auto' }
          //   autoplay={ { delay: 5000 } }
          navigation={ {
            prevEl: '.slider_swiper_sercicesmini_prev',
            nextEl: '.slider_swiper_sercicesmini_next',
          } }
        >
          { data.map((item, i) =>
            <SwiperSlide key={ i } className={ cl.swiperSlide }>
              <ServicesMiniItem item={ item } />
            </SwiperSlide>,
          ) }
        </Swiper>
      </div>
      <div className={ cl.navigation }>
        <div className={ cn(cl.navigation__prev, 'slider_swiper_sercicesmini_prev') }><i className='icon-angle-left' /></div>
        <div className={ cn(cl.navigation__next, 'slider_swiper_sercicesmini_next') }><i className='icon-angle-right' /></div>
      </div>
    </React.Fragment>
  );
};
