import { useState } from 'react';
import { SuccessfullySent } from 'components/modal';
import { Input } from 'components/ui/form';
import { eventYm } from 'components/ui/yandex-metrika';
import { schema } from 'form-helpers/calculateCostTerms/schema';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { selectEmailCalculateCostTermsSliceLoading, SendCalculateCostTerms } from 'store/calculateCostTerms';
import { useAppDispatch } from 'store/hooks';
import { TCalcCostTermsCertif } from 'types/form';
import styles from './styles.module.scss';


export const CalculateCostTerms = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectEmailCalculateCostTermsSliceLoading);
  const [open, setOpen] = useState(false);

  return (
    <div className={ styles.form }>
      <div className={ styles.title }>
        <i className="icon icon-long_arrow_right"></i>
        <div className={ styles.title__txt }>
          Рассчитайте стоимость <br />и сроки сертификаци
        </div>
      </div>
      <div className={ styles.desc }>
        Объясним какой документ необходим для вашей продукции
      </div>
      <Formik<TCalcCostTermsCertif>
        initialValues={ { message: '', contacts: '', type_message: 'Рассчитайте стоимость и сроки сертификации' } }
        validationSchema={ schema }
        onSubmit={ (values, { setSubmitting, resetForm }) => {
          dispatch(SendCalculateCostTerms(values)).unwrap()
            .then(() => {
              setOpen(true);
              setSubmitting(false);
              eventYm('reachGoal', 'order');
              resetForm();
            });
        } }
      >
        { ({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className={ styles.form_template }
            onSubmit={ handleSubmit }
          >
            <Input
              type='hidden'
              id="type_message"
              value="Рассчитайте стоимость и сроки сертификации"
            />
            <Input
              id="message"
              onChange={ handleChange }
              onBlur={ handleBlur }
              value={ values.message }
              placeholder='На что нужно получить сертификат?'
              error={ !!errors.message }
              errorText={ errors.message }
            />
            <Input
              id="contacts"
              value={ values.contacts }
              onChange={ handleChange }
              onBlur={ handleBlur }
              placeholder='Введите телефон или email?'
              error={ !!errors.contacts }
              errorText={ errors.contacts }
            />
            <button type='submit' disabled={ isSubmitting || loading } className={ styles.btn }>Рассчитать стоимость</button>
          </form>
        ) }
      </Formik>
      <div className={ styles.personalData }>
        Нажимая кнопку, вы даете <a href="#">согласие на обработку персональных данных</a>
      </div>
      <SuccessfullySent
        open={ open }
        onCancel={ () => setOpen(false) }
        title="Спасибо! Ваша заявка успешно отправлена, мы <br /> свяжемся с вами в течение 10 мин."
      />
    </div>
  );
};
