import { useEffect } from 'react';
import cn from 'classnames';
import ServiceCard from 'components/documentsForConfirmation/card';
import { AppLayout, AppNavigation } from 'components/ui/app';
import { DocumentCardSkeleton } from 'components/ui/skeleton';
import { useSelector } from 'react-redux';
import {
  getCertificates,
  selectCertificateLoading,
  selectCertificates,
} from 'store/certificates';
import { useAppDispatch } from 'store/hooks';
import styles from './styles.module.scss';

export const AllServices = () => {
  const dispatch = useAppDispatch();
  const certificates = useSelector(selectCertificates);
  const loading = useSelector(selectCertificateLoading);

  useEffect(() => {
    dispatch(getCertificates({ pagination: { page: 1, size: 20 } }));
  }, [dispatch]);

  return (
    <AppLayout flex={ true }>
      <AppNavigation />
      <h2 className={ styles.heading }>Сертификация продукции</h2>
      <div className={ styles.desc }>Оформление и получение сертификатов соответствия <br /> и деклараций соответствия по самым выгодным условиям</div>

      <div className={ cn(styles.grid, styles.container_mb) }>
        { loading.get ? Array(4).fill(null).map((_, i) => (
          <DocumentCardSkeleton key={ i } />
        )) : (
          certificates?.map((item, i) => (
            <ServiceCard data={ item } key={ i } />
          ))
        ) }
      </div>
      <div className={ styles.container_mb } />
    </AppLayout>
  );
};
