import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
  toggle: boolean;
  onToggle: () => void;
};

export const Hamburger = ({ toggle, onToggle }: Props) => {
  const style = cn({
    [styles.hamburger]: true,
    [styles.open]: toggle,
  });

  return (
    <div className={ styles.warp }>
      <div
        className={ style }
        onClick={ onToggle }>
        <div />
      </div>
    </div>
  );
};
