import { MyImage } from 'components/ui/image';
import styles from './styles.module.scss';

type Props = {
  image: string;
  title: string;
  certificate: string;
};

export const Item = ({ image, title, certificate }: Props) => (
  <div className={ styles.item }>
    <MyImage
      src={ image }
      width="100%"
      alt={ title }
      className={ styles.image }
    />
    <div className={ styles.content }>
      <div className={ styles.name }>{ title }</div>
      <div className={ styles.certificate }>
        Аттестат аккредитации: <br />
        { certificate }
      </div>
    </div>
  </div>
);
