import React, { useState } from 'react';
import { SuccessfullySent } from 'components/modal';
import { CheckboxSocial, Input } from 'components/ui/form';
import { eventYm } from 'components/ui/yandex-metrika';
import { schema } from 'form-helpers/quiz/schema';
import { ErrorMessage, Formik } from 'formik';
import InputMask from 'react-input-mask';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SendQuiz } from 'store/quiz';
import { TQuiz } from 'types/form';
import styles from './styles.module.scss';

type Props = {
  qvalue: any;
};

export const QuizForm = React.memo(({ qvalue }: Props) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.emailQuiz);
  const [successOpen, setSuccessOpen] = useState(false);

  return (
    <React.Fragment>
      <Formik<TQuiz>
        initialValues={ {
          type_message: 'Рассчитайте стоимость сертификации за 30 секунд',
          name: '',
          contacts: '',
          social: false,
          qvalue,
        } }
        validationSchema={ schema }
        onSubmit={ (values, { setSubmitting, resetForm }) => {
          dispatch(SendQuiz(values)).unwrap()
            .then(() => {
              setSuccessOpen(true);
              eventYm('reachGoal', 'order');
              resetForm();
            });
          setSubmitting(false);
        } }
      >
        { ({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={ handleSubmit } className={ styles.result }>
            <div className={ styles.result__title }>Оставьте свои контакты, закрепим скидку -25%</div>
            <div className={ styles.result_form }>
              <Input
                id='name'
                placeholder='Ваше имя'
                value={ values.name }
                onChange={ handleChange }
                onBlur={ handleBlur }
                error={ !!errors.name }
              />
              <div>
                <InputMask
                  id='contacts'
                  value={ values.contacts }
                  mask='+7 (999) 999-99-99'
                  placeholder='+7 (999) 999-99-99'
                  className={ styles.input }
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                />
                <ErrorMessage name="contacts" className={ styles.input__error } component="div" />
              </div>
              <CheckboxSocial
                id='social'
                checked={ values.social }
                onChange={ handleChange }
              />
              <button
                type="submit"
                disabled={ isSubmitting || loading }
                className={ styles.btn }
              >
                Рассчитать стоимость
              </button>
            </div>
          </form>
        ) }
      </Formik>
      <SuccessfullySent
        open={ successOpen }
        onCancel={ () => setSuccessOpen(false) }
        title="Спасибо! Ваша заявка успешно отправлена, мы <br /> свяжемся с вами в течение 10 мин."
      />
    </React.Fragment>
  );
});
