import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import cl from './styles.module.scss';

type TMenu = {
  menu: Array<{
    path: string;
    name: string;
    element: any;
  }>;
};

export const SldierMenu = React.memo(({ menu }: TMenu) => {
  const [scrollOffset, setScrollOffset] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const touchStartXRef = useRef<number | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [initialMouseX, setInitialMouseX] = useState(0);

  const speedScale = 0.3;

  useEffect(() => {
    // Проверяем, если контейнер шире, чем меню, то показываем стрелки
    const containerWidth = containerRef.current?.getBoundingClientRect().width || 0;
    const menuWidth = containerRef.current?.scrollWidth || 0;

    setShowLeftArrow(scrollOffset < 0);
    setShowRightArrow(scrollOffset > containerWidth - menuWidth);
  }, [scrollOffset]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartXRef.current = e.touches[0].pageX;
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (touchStartXRef.current !== null) {
      const touchMoveX = e.touches[0].pageX;
      const touchDiff = touchMoveX - touchStartXRef.current;

      // Изменяем смещение меню в противоположном направлении
      setScrollOffset((prevOffset) => {
        const containerWidth = containerRef.current?.getBoundingClientRect().width || 0;
        const menuWidth = containerRef.current?.scrollWidth || 0;
        const maxScrollOffset = containerWidth - menuWidth;

        return Math.min(Math.max(prevOffset - touchDiff, maxScrollOffset), 0);
      });
    }
  };

  const handleTouchEnd = () => {
    touchStartXRef.current = null;
  };

  const handleScrollLeft = () => {
    setScrollOffset((prevOffset) => Math.min(prevOffset + 100, 0));
  };

  const handleScrollRight = () => {
    const containerWidth = containerRef.current?.getBoundingClientRect().width || 0;
    const menuWidth = containerRef.current?.scrollWidth || 0;

    setScrollOffset((prevOffset) => Math.max(prevOffset - 100, containerWidth - menuWidth));
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setInitialMouseX(e.clientX);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      const mouseDiff = (e.clientX - initialMouseX) * speedScale;

      setScrollOffset((prevOffset) => {
        const containerWidth = containerRef.current?.getBoundingClientRect().width || 0;
        const menuWidth = containerRef.current?.scrollWidth || 0;
        const maxScrollOffset = containerWidth - menuWidth;

        return Math.min(Math.max(prevOffset + mouseDiff, maxScrollOffset), 0);
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className={ cl.container } id="containerMenu"
      onTouchStart={ handleTouchStart }
      onTouchMove={ handleTouchMove }
      onTouchEnd={ handleTouchEnd }
      onMouseDown={ handleMouseDown }
      onMouseMove={ handleMouseMove }
      onMouseUp={ handleMouseUp }
    >
      { showLeftArrow && (
        <div className={ cn(cl.prev) } onClick={ handleScrollLeft }>
          <i className="icon-angleleft" />
        </div>
      ) }
      <div
        ref={ containerRef }
        className={ cl.menu }
        style={ { transform: `translateX(${scrollOffset}px)` } }
        itemScope={ true }
        itemType="http://schema.org/SiteNavigationElement"
      >
        { menu &&
          menu.map((item) => (
            <div
              key={ item.path }
              className={ cl.menu__link }
              itemProp="itemListElement"
              itemScope={ true }
              itemType="http://schema.org/ItemList"
            >
              <Link to={ item.path } itemProp="url">
                { item.name }
              </Link>
              <meta itemProp="name" content={ item.name } />
            </div>
          )) }
      </div>
      { showRightArrow && (
        <div className={ cn(cl.next) } onClick={ handleScrollRight }>
          <i className="icon-angleright" />
        </div>
      ) }
    </div>
  );
});
