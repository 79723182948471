import { AppLayout, AppNavigation } from 'components/ui/app';
import styles from './styles.module.scss';

export const Person = () => {
  return (
    <AppLayout flex={ true }>
      <AppNavigation />
      <div className={ styles.body }>
        <h2 className={ styles.heading }>Согласие на обработку персональных данных</h2>
        <p>Я (далее - Заявитель) даю согласие Обществу с ограниченной ответственностью &laquo;Дельта-Сертификат&raquo;&lrm;, ИНН</p>
        <p>7722403820, юридический адрес 127247, город Москва, ул 800-Летия Москвы, д. 12, этаж 1 помещ. III ком.1, тел. 8 (800) 777-61-58, (далее &mdash; Оператор) на автоматизированную, а также без использования средств автоматизации, обработку моих персональных данных, предоставленных мною Оператору посредством сети Интернет через сайт https://delta-certificate.com, а именно:</p>
        <ul className='ul'>
          <li>фамилия, имя и отчество;</li>
          <li>номера телефонов;</li>
          <li>email;</li>
        </ul>
        <p><span>включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование удаления и уничтожения, отправка информационных писем в мессенджеры, а также на вышеуказанную обработку иных моих персональных данных, полученных в результате их обработки, включая обработку третьими лицами.<br /><br />Целями обработки персональных данных является: информирование Пользователя посредством мобильной связи и мессенджеров.<br />Указанное согласие дано на срок 1 (один) календарный год. В случае, если в течение указанного срока с Заявителем будет заключен договор оказания услуг/выполнения работ, настоящее Согласие Заявителя сохраняет силу в течение всего срока действия каждого договора, а также в течение 1 (одного) календарного года с даты прекращения обязательств сторон по договорам.<br /><br />Я уведомлен, что предоставление указанного выше согласия является моим правом, а не обязанностью, данные согласия могут быть отозваны в порядке, предусмотренном</span></p>
        <p>&nbsp;</p>
      </div>
    </AppLayout>
  );
};
