import { memo } from 'react';
// import cn from 'classnames';
import { ListOfParameters, ShortList } from 'components/static';
import { AppLayout, AppNavigation } from 'components/ui/app';
import { Button } from 'components/ui/button';
import { Link } from 'react-router-dom';
import { router } from 'routers/router';
import image1 from 'assets/images/page/chemical-laboratory.png';
import { listOfParameters, listOfSubstances } from './data';
import styles from './styles.module.scss';

export const ChemicalLaboratory = memo(() => {
  return (
    <AppLayout>
      <div className="container">
        <div className="columns">
          <AppNavigation />
          <h2 className={ styles.heading }>Аккредитованная химическая и микробиологическая лаборатория</h2>
        </div>
      </div>
      <section className="container">
        <div className="columns">
          <ShortList list={ listOfSubstances } />
          <div className={ styles.desc }>Проверим Вашу продукцию по всем необходимым ПАРАМЕТРАМ</div>
          <ListOfParameters data={ listOfParameters } />
          <img src={ image1 } className={ styles.image } alt="" />
        </div>
      </section>
      <div className="section-mb">
        <Link to={ router.main.path }>
          <Button
            color='redDarken'
            ttu={ true }
            className={ styles.btn }
          >
            оформить сертификацию
          </Button>
        </Link>
      </div>
    </AppLayout>
  );
});
