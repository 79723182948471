import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import * as Api from 'types/api';
import { Pagination, Sort } from 'types/pagination';
import { PromotionsDiscount } from 'types/promotionsDiscounts';

export const getPromotionsDiscount = createAsyncThunk('promotionsDiscount/getPromotionsDiscount', async (payload: Api.GetPromotionsDiscountPayload, { getState }) => {
  const { reviews } = getState() as RootState;

  const response = await fetchers.getPromotionsDiscount({
    pagination: {
      ...payload.pagination,
      sortOrder: reviews.textPagination.sortOrder,
    },
  });

  return response.data;
},
);

interface AppState {
  loading: {
    get: boolean;
  };
  items: PromotionsDiscount[] | null;
  pagination: Pagination & Sort;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: {
    get: false,
  },
  items: null,
  pagination: {
    page: 1,
    size: 8,
    itemCount: 0,
    pageSize: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    sortField: 'createdAt',
    sortOrder: 'asc',
  },
};

export const promotionsDiscountSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    clearPromotionsDiscount: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPromotionsDiscount.pending, (state) => {
      state.loading.get = true;
    });
    builder.addCase(getPromotionsDiscount.rejected, (state) => {
      state.loading.get = false;
    });
    builder.addCase(getPromotionsDiscount.fulfilled, (state, action) => {
      state.loading.get = false;
      state.items = Array.isArray(state.items) ? [...state.items, ...action.payload.data] : action.payload.data;
      state.pagination = action.payload.pagination;
    });
  },
});

export const { clearPromotionsDiscount } = promotionsDiscountSlice.actions;

export const selectPromotionsDiscount = (state: RootState) => state.promotionsDiscount.items;
export const selectPromotionsDiscountPagination = (state: RootState) => state.promotionsDiscount.pagination;
export const selectPromotionsDiscountLoading = (state: RootState) => state.promotionsDiscount.loading;

export default promotionsDiscountSlice.reducer;