import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  placeholder?: string;
  onClick?: () => void;
  onChange?: (...arg: any) => void;
  size: 'xs' | 'md' | 'lg';
};

export const InputSearchButton = (props: Props) => {
  return (
    <div className={ styles.search }>
      <input
        type="text"
        placeholder={ props.placeholder }
        className={ styles.search__input }
        onClick={ props.onClick }
        onChange={ props.onChange }
      />
      <button
        type='button'
        className={ classNames(styles.search__btn, styles[`btn_${props.size}`]) }
      >
        <i className='icon-search' />
      </button>
    </div>
  );
};

InputSearchButton.defaultProps = {
  size: 'md',
  placeholder: 'Поиск...',
};