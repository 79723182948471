import React from 'react';
import classNames from 'classnames';
import type { TextAreaProps as RcTextAreaProps } from 'rc-textarea';
import RcTextArea from 'rc-textarea';
import styles from './styles.module.scss';


// export const Input = (props: InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement> | null) => {

// interface Props extends React.HTMLAttributes {
export interface TextAreaProps extends RcTextAreaProps {
  size?: 'xs' | 'md' | 'lg';
  error?: boolean;
  disabled?: boolean;
  errorText?: string;
  [key: `data-${string}`]: string | undefined;
}

export const TextArea = ({ size, placeholder, error, errorText, ...lastProps }: TextAreaProps) => {
  const prefixCls = 'input';

  return (
    <div className={ styles.formInput }>
      <RcTextArea
        className={ classNames(
          {
            [styles.input]: true,
            [styles[`${prefixCls}_xs`]]: size === 'xs',
            [styles[`${prefixCls}_md`]]: size === 'md',
            [styles[`${prefixCls}_lg`]]: size === 'lg',
            [styles[`${prefixCls}_error`]]: errorText,
          }) }
        placeholder={ placeholder }
        { ...lastProps }
      />
      { errorText && <div className={ styles.error }>{ errorText }</div> }
    </div>
  );
};

TextArea.defaultProps = {
  size: 'md',
  placeholder: 'Ваше значение',
};