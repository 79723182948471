import React from 'react';
import { PriceHeader } from './header';
import { PriceItem } from './item';

type Props = {
  data: {
    title: string;
    items: Array<{
      title: string;
      desc: string;
      price: string;
    }>;
  };
};

export const PriceHeaderItem = ({ data }: Props) => {
  console.log();

  return (
    <React.Fragment>
      <PriceHeader title={ data.title } />
      <PriceItem child={ data.items } max={ 3 } />
    </React.Fragment>
  );
};