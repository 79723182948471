import cn from 'classnames';
import { A11y, EffectFade, Navigation } from 'swiper';
// eslint-disable-next-line import/order
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { Item } from './item';
import styles from './styles.module.scss';

const data = [
  {
    image: '/images/company/atestat1.jpg',
    title: 'Аккредитованная химическая и микробиологическая лаборатория',
    certificate: '№ RA.RU.21ОА52',
  },
  {
    image: '/images/company/atestat2.jpg',
    title: 'Аккредитованная лаборатория электрики “Центра конроля машин и оборудования ООО ИЛ73”',
    certificate: '№ RA.RU.21ОМ18',
  },
  {
    image: '/images/company/atestat3.jpg',
    title: 'Аккредитованный орган по сертификации',
    certificate: '№ RA.RU.11АМ05',
  },
  {
    image: '/images/company/atestat4.jpg',
    title: 'Аккредитованная лаборатория механики и механизмов',
    certificate: '№ RA.RU.21ОМ18',
  },
  {
    image: '/images/company/atestat5.jpg',
    title: 'Испытательная лаборатория мебели и мебельной продукции',
    certificate: '№ RA.RU.11АМ05',
  },
  {
    image: '/images/company/atestat6.jpg',
    title: 'Испытательная лаборатория мебели и мебельной продукции',
    certificate: '№ RA.RU.11АМ05',
  },
];

export const ListSlider = () => {
  const uid = Date.now();

  return (
    <div className={ cn(styles.sliderList) }>
      <div className="container">
        <div className="flex-row-column">
          <div className={ styles.navigation }>
            <div className={ cn(styles.navigation__prev, `slider_swiper_${uid}_prev`) }><i className='icon-angle-left' /></div>
            <div className={ cn(styles.navigation__next, `slider_swiper_${uid}_next`) }><i className='icon-angle-right' /></div>
          </div>
          <Swiper
            className={ styles.swiper }
            modules={ [Navigation, A11y, EffectFade] }
            navigation={ {
              prevEl: `.slider_swiper_${uid}_prev`,
              nextEl: `.slider_swiper_${uid}_next`,
            } }
            coverflowEffect={ {
              rotate: 20,
              stretch: 25,
              depth: 250,
              modifier: 1,
              slideShadows: false,
            } }
            breakpoints={ {
              1001: {
                spaceBetween: 20,
                slidesPerView: 4,
              },
              960: {
                spaceBetween: 20,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 20,
                slidesPerView: 3,
              },
              480: {
                spaceBetween: 20,
                slidesPerView: 2,
              },
              300: {
                spaceBetween: 0,
                slidesPerView: 1,
              },
            } }
          >
            { data.map((item, i) => (
              <SwiperSlide key={ i }>
                <Item
                  image={ item.image }
                  title={ item.title }
                  certificate={ item.certificate }
                />
              </SwiperSlide>
            )) }
          </Swiper>
        </div>
      </div>
    </div>
  );
};
