type TServicesItem = {
  img: string;
  title: string;
  describtion?: string;
};

export const data: TServicesItem[] = [
  {
    img: '/images/services/laba.jpg',
    title: 'Аккредитованная химическая лаборатория',
    describtion: 'Более 200 видов испытаний',
  },
  {
    img: '/images/services/sert.jpg',
    title: 'Сертификаты и декларации соответствия',
    describtion: 'Более 340 документов',
  },
  {
    img: '/images/services/lab2.jpg',
    title: 'Аккредитованная лаборатория электрики',
    describtion: 'Сертификация 004 и 020 ТР ТС ',
  },
  {
    img: '/images/services/lab3.jpg',
    title: 'Оформление документов для маркетплейсов',
    describtion: 'Помощь от экспертов',
  },
  {
    img: '/images/services/lab5.jpg',
    title: 'Аккредитованная лаборатория механики',
    describtion: 'Сертификация 010 ТР ТС ',
  },
  {
    img: '/images/services/lab4.jpg',
    title: 'Оформление паспортов,ТУ, СТО',
    describtion: 'Техническая документация',
  },
  {
    img: '/images/services/lab6.jpg',
    title: 'Испытания и сертификация мебели',
    describtion: 'полный спектр испытаний',
  },
  {
    img: '/images/services/lab8.jpg',
    title: 'Лаборатория безопасности колесных транспортных средств',
  },
];