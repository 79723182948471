import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import * as Api from 'types/api';

export const SendRequestPhoneСall = createAsyncThunk('requestPhoneСall/SendRequestPhoneСall', async (payload: Api.SendRequestPhoneСallPayload) => {
  const response = await fetchers.sendRequestPhoneСall(payload);

  return response.data;
},
);

// Define a type for the slice state
interface AppState {
  loading: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: false,
};

export const emailRequestPhoneСallSlice = createSlice({
  name: 'emailRequestPhoneСall',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SendRequestPhoneСall.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SendRequestPhoneСall.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(SendRequestPhoneСall.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectEmailRequestPhoneСallLoading = (state: RootState) => state.emailRequestPhoneСall.loading;

export default emailRequestPhoneСallSlice.reducer;