import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import * as Api from 'types/api';

export const sendFeedback = createAsyncThunk('feedback/sendFeedback', async (payload: Api.SendFeedback) => {
  const response = await fetchers.sendFeedback(payload);

  return response.data;
},
);

// Define a type for the slice state
interface AppState {
  loading: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: false,
};

export const emailFeedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    clearAccreditation: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(sendFeedback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendFeedback.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(sendFeedback.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

// export const { setLoading, getService, setService } = accreditationSlice.actions;
export const selectFeedbackLoading = (state: RootState) => state.emailfeedback.loading;

export default emailFeedbackSlice.reducer;