import React, { memo, useEffect, useState } from 'react';
import { ReviewsModal } from 'components/form/reviews';
import { AppLayout, AppNavigation } from 'components/ui/app';
import { Button } from 'components/ui/button';
import { MyImage } from 'components/ui/image';
import { ReviewsImageSkeleton, ReviewsTextSkeleton } from 'components/ui/skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { router } from 'routers/router';
import { useAppDispatch } from 'store/hooks';
import {
  clearReviews,
  getReviewsImage, getReviewsText,
  selectReviewsImage, selectReviewsImagePagination,
  selectReviewsLoading, selectReviewsText, selectReviewsTextPagination,
} from 'store/reviews';
import styles from './styles.module.scss';

export const Reviews = memo(() => {
  const dispatch = useAppDispatch();
  const reviewsImage = useSelector(selectReviewsImage);
  const reviewsText = useSelector(selectReviewsText);
  const loading = useSelector(selectReviewsLoading);
  const imagePagination = useSelector(selectReviewsImagePagination);
  const textPagination = useSelector(selectReviewsTextPagination);
  const [open, setOpen] = useState(false);
  const [currentPageImage, setCurrentPageImage] = useState(2);
  const [currentPageText, setCurrentPageText] = useState(2);

  useEffect(() => {
    dispatch(getReviewsText({ pagination: { page: 1, size: 4 } }));
    dispatch(getReviewsImage({ pagination: { page: 1, size: 4 } }));

    return () => {
      dispatch(clearReviews());
    };
  }, [dispatch]);

  const goNextImagePage = () => {
    setCurrentPageImage((prev) => prev + 1);

    dispatch(getReviewsImage({ pagination: { page: currentPageImage, size: 4 } }));
  };

  const goNextTextPage = () => {
    setCurrentPageText((prev) => prev + 1);

    dispatch(getReviewsText({ pagination: { page: currentPageText, size: 4 } }));
  };

  return (
    <AppLayout flex={ true }>
      <AppNavigation />
      <h2 className={ styles.heading }>Отзывы наших клиентов</h2>
      <p className={ styles.desc }>Непрерывно улучшаем наш сервис. Поделитесь вашим отзывом с нами. <br /> Помогите нам стать лучше для вас</p>
      <Button
        color='darken'
        ttu={ true }
        className={ styles.btn }
        onClick={ () => setOpen(true) }
      >
        написать отзыв
      </Button>
      <ReviewsModal open={ open } onClose={ () => setOpen(false) } />


      <div className={ styles.reviews_grid }>
        { loading.getImage ?
          Array(4).fill(null).map((_, i) => (<div className={ styles.reviews_item } key={ i }>
            <ReviewsImageSkeleton />
          </div>),
          ) : (
            reviewsImage?.map((item) => (
              <div className={ styles.reviews_item } key={ item.id }>
                <MyImage
                  src={ item.image }
                  width='289px'
                  height='412px'
                  alt={ item.authorName }
                  zoom={ true }
                />
              </div>
            ))
          ) }
      </div>
      { imagePagination.hasNextPage && (
        <div
          className={ styles.btn_load }
          onClick={ goNextImagePage }
        >
          Загрузить еще
        </div>
      ) }

      <div className={ styles.revText }>
        { loading.getImage ?
          Array(4).fill(null).map((_, i) => <ReviewsTextSkeleton key={ i } />,
          ) : (
            reviewsText?.map((item) => (
              <div className={ styles.revText__item } key={ item.id }>
                <div className={ styles.revText__text }>{ item.desc }</div>
                <div className={ styles.revText__author }>{ item.authorName }</div>
              </div>
            ))
          ) }
      </div>
      { textPagination.hasNextPage && (
        <div
          className={ styles.btn_load }
          onClick={ goNextTextPage }
        >
          Загрузить еще
        </div>
      ) }

      <div className="section-mb">
        <Link to={ router.main.path }>
          <Button
            color='redDarken'
            ttu={ true }
            className={ styles.btnDelta }
          >
            оформить сертификацию
          </Button>
        </Link>
      </div>
    </AppLayout>
  );
});
