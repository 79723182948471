import { MyImage } from 'components/ui/image';
import { Link } from 'react-router-dom';
import { PromotionsDiscount } from 'types/promotionsDiscounts';
import styles from './styles.module.scss';

type Props = {
  data: PromotionsDiscount;
};

export const PromotionItem = ({ data }: Props) => (
  <div className={ styles.item }>
    <div className={ styles.image }>
      <MyImage src={ data.image } width='100%' height='280px' alt='' />
    </div>
    <Link to={ `/promotions-and-discounts/${data.id}` } className={ styles.link }>{ data.title }</Link>
  </div>
);