import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type TProps = {
  className?: string;
  children: ReactNode;
};

const Bookmark = ({ className, children }: TProps) => (
  <div className={ cn([styles.bookmark, className]) }>{ children }</div>
);

export default Bookmark;
