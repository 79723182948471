import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

if (process.env.REACT_APP_PRODUCTION === 'true') {
  const js = document.createElement('script');

  js.type = 'text/javascript';
  js.append(`
      (function(d, w, c) {
          w.ChatraID = '7Az9pJRAPr8W78Yca';
          var s = d.createElement('script');
          w[c] = w[c] || function() {
              (w[c].q = w[c].q || []).push(arguments);
          };
          s.async = true;
          s.src = 'https://call.chatra.io/chatra.js';
          if (d.head) d.head.appendChild(s);
      })(document, window, 'Chatra')
  `);

  document.body.appendChild(js);
}

reportWebVitals();
