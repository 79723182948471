export const listOfParameters = [
  'Виброустойчивость',
  'Вибропрочность на частотах 10-5000Гц',
  'Измерение локальной и общей вибрации',
  'Уровень звука',
  'Уровень звукого давления и звуковой мощности',
  'Виброускорение, виброскорость, виброперемещение',
  'Стойкость к повышенной температуре',
  'Стойкость к пониженной температуре',
  'Влагостойкость',
  'Испытание циклическим режимом температуры-влажности',
  'Воздействие инея',
  'Испытания контактных зажимов на повреждаемость проводника',
  'Испытания частично изолированных штырей вилок на истирание',
  'Надежность контактных соединений электрооборудования',
  'Испытание механическим давлением при высокой температуре',
  'Проверка защиты оборудования от поражения электрическим током и доступа к опасным частям',
  'Проверка требований к конструкции и размерам',
  'Стойкость оборудования к удару и падению',
  'Стойкость к статическим нагрузкам (сжатие, растяжение)',
  'Испытание крутящим моментом',
  'Стойкость к коррозии',
  'Устойчивость к старению',
  'Твердость по Бринеллю, Роквеллу, Шору',
  'Износостойкость оборудования',
  'Пожароопасность',
  'Огнестойкость',
  'Трекингостойкость.',
  'Стойкость к воздействию игольчатого пламени, пламени 50 Вт, пламени 500 Вт',
];

export const listOfSubstances = [
  { title: 'Станки деревообрабатывающие бытовые' },
  { title: 'Инструмент механизированный, в том числе электрический' },
  {
    title: 'Оборудование технологическое для лесозаготовки, лесобирж и лесосплава:',
    children: [
      { title: 'пилы бензиномоторные;' },
      { title: 'пилы цепные электрические' },
    ],
  },
];