import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import * as Api from 'types/api';

export const SendQuestionToExpert = createAsyncThunk('questiontoexpert/SendQuestionToExpert', async (payload: Api.SendQuestionToExpertPayload) => {
  const response = await fetchers.sendQuestionToExpert(payload);

  return response.data;
},
);

// Define a type for the slice state
interface AppState {
  loading: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: false,
};

export const emailQuestionToExpertSlice = createSlice({
  name: 'mailquestiontoexpert',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SendQuestionToExpert.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SendQuestionToExpert.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(SendQuestionToExpert.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const selectEmailQuestionToExpertLoading = (state: RootState) => state.emailQuestionToExpert.loading;

export default emailQuestionToExpertSlice.reducer;