import Modal from 'components/ui/modal';
import styles from './styles.module.scss';

type Props = {
  title: string;
  open: boolean;
  onCancel: () => void;
};

export const SuccessfullySent = ({ title, open, onCancel }: Props) => {
  return (
    <Modal open={ open } onCancel={ onCancel }>
      <div className={ styles.modal }>
        <i className='icon-check'></i>
        <div className={ styles.modal__title } dangerouslySetInnerHTML={ { __html: title } } />
      </div>
    </Modal>
  );
};
