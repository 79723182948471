export const listOfParameters = [
  'Устойчивость к электростатическим разрядам',
  'Устойчивость к провалам, прерываниям и выбросам напряжения',
  'Устойчивость к магнитным полям',
  'Оценка соответствия нормам излучаемых помех в диапазоне 30 МГц-6ГГц',
  'Устойчивость к микросекундным и наносекундным помехам ',
  'Оценка соответствия нормам Напряжения помех на сетевых зажимах, зажимах управления и нагрузки в диапазоне 9кГц-30 МГц',
  'Устойчивость к затухающим волнам',
  'Устойчивость к кондуктивным помехам, наведенным радиочастотными электромагнитными полями',
  'Оценка влияния электромагнитных полей на человека от осветительного оборудования',
  'Оценка сопротивления изоляции и электрической прочности',
  'Проверка путей утечки и воздушных зазоров',
  'Превышение температуры при различных режимах работы',
  'Оценка заземления',
  'Проверка включающей и отключающей способностей',
  'Устойчивость к нормальной работе и работе в аномальном режиме',
  'Электрическая износостойкость',
  'Токи утечки',
  'Потребляемая мощность и ток',
  'Устойчивость к импульсному напряжению',
  'Проверка защиты от поражения электрическим током',
  'Устойчивость к перегрузкам',
  'Устойчивость к токам поверхностного разряда',
  'Напряжение',
  'Оценка ультрафиолетового излучения',
];

export const listOfSubstances = [
  {
    title: '1. Электрические аппараты и приборы бытового назначения:',
    children: [
      { title: 'для приготовления и хранения пищи и механизации кухонных работ;' },
      { title: 'для обработки(стирки, глажки, сушки, чистки) белья, одежды и обуви;' },
      { title: 'для чистки и уборки помещений;' },
      { title: 'для поддержания и регулировки микроклимата в помещениях;' },
      { title: 'санитарно - гигиенические;' },
      { title: 'для ухода за волосами, ногтями и кожей;' },
      { title: 'для обогрева тела;' },
      { title: 'вибромассажные;' },
      { title: 'игровое, спортивное и тренажерное оборудование;' },
      { title: 'аудио - и видеоаппаратура, приемники теле - и радиовещания;' },
      { title: 'швейные и вязальные;' },
      { title: 'блоки питания, зарядные устройства, стабилизаторы напряжения;' },
      { title: 'для садово - огородного хозяйства;' },
      { title: 'для аквариумов и садовых водоемов;' },
      { title: 'электронасосы;' },
      { title: 'оборудование световое и источники света;' },
      { title: 'изделия электроустановочные;' },
      { title: 'удлинители;' },
      { title: 'автоматические устройства управления бытовым электрооборудованием;' },
      { title: 'пульты и панели управления, контроллеры.' },
    ],
  },
  {
    title: '2. Персональные электронные вычислительные машины (персональные компьютеры):',
    children: [
      { title: 'персональные электронные вычислительные машины, в том числе системные блоки;' },
      { title: 'аппараты кассовые, в том числе работающие совместно с вычислительной машиной.' },
    ],
  },
  {
    title: '3. Низковольтное оборудование бытового и офисного назначения, подключаемое к персональным электронным вычислительным машинам:',
    children: [
      { title: 'сканеры, принтеры и копировальные аппараты(включая многофункциональные устройства);' },
      { title: 'мониторы;' },
      { title: 'источники бесперебойного питания;' },
      { title: 'активные акустические системы;' },
      { title: 'мультимедийные проекторы;' },
    ],
  },
  { title: '4. Инструмент электронагревательный.' },
  { title: '5. Инструменты электромузыкальные.' },
  { title: '6. Кабели, провода и шнуры.' },
  { title: '7. Выключатели автоматические, устройства защитного отключения, плавкие предохранители, распределительные устройства, переключатели, контакторы, пускатели.' },
];