import * as yup from 'yup';
import 'yup-phone';
import { REQUIRED_FIELD } from '../message';

export const schema = yup.object().shape({
  type_message: yup.string().required(REQUIRED_FIELD),
  message: yup.string().required(REQUIRED_FIELD),
  // contacts: yup.string().email('Обязательно заполните Email').required(REQUIRED_FIELD),
  contacts: yup.string()
    // .email("Enter a valid email")
    .required('Обязательно укажите Email или телефон')
    .test('test-name', 'Укажите правильно Email или телефон',
      function (value: any) {
        const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
        const isValidEmail = emailRegex.test(value);
        const isValidPhone = phoneRegex.test(value);

        if (!isValidEmail && !isValidPhone) {
          return false;
        }

        return true;
      }),
});