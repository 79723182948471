import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { InputSearchButton } from 'components/ui/input/search';
import Modal from 'components/ui/modal';
import { useSelector } from 'react-redux';
import {
  getGeo,
  selectGeoCity,
  selectGeoCurrent,
} from 'store/geo';
import { useAppDispatch } from 'store/hooks';
import { ICityItem } from 'types/geo';
import styles from './styles.module.scss';


const defaultCity = [
  {
    id: 365,
    name: 'Москва',
  },
  {
    id: 366,
    name: 'Санкт-Петербург',
  },
  {
    id: 292,
    name: 'Екатеринбург',
  },
  {
    id: 234,
    name: 'Новосибирск',
  },
  {
    id: 92,
    name: 'Краснодар',
  },
];

export const City = React.memo(() => {
  const dispatch = useAppDispatch();
  const city = useSelector(selectGeoCity);
  const baseCity = useSelector(selectGeoCurrent);
  const [open, setOpen] = useState(false);
  const [currentGeo, setCurrentGeo] = useState<ICityItem | null>(baseCity);
  const [search, setSearch] = useState<ICityItem[] | null>(null);
  const historyGeo = localStorage.getItem('geo');

  useEffect(() => {
    dispatch(getGeo());
  }, [dispatch]);

  useEffect(() => {
    if (historyGeo) {
      setCurrentGeo(JSON.parse(historyGeo));
    } else {
      setCurrentGeo(baseCity);
    }
  }, [baseCity]);

  const getSearch = (event: React.MouseEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length > 3) {
      const s = city?.filter((item) => item.name.toLowerCase().includes(event.currentTarget.value.toLowerCase())).slice(0, 6) || null;

      setSearch(s);
    } else {
      setSearch(null);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onChangeCity = (city: ICityItem) => {
    setCurrentGeo(city);
    localStorage.setItem('geo', JSON.stringify(city));
    setOpen(false);
  };

  return (
    <div className={ styles.city }>
      <div
        className={ styles.city_info }
        onClick={ () => setOpen(true) }
      >
        Ваш город: <span>{ currentGeo?.name }</span>
      </div>

      <Modal
        open={ open }
        onCancel={ () => setOpen(false) }
        className={ styles.modal }
      >
        <div className={ styles.group }>
          <div className={ styles.city__title }>Вы здесь: <span>{ currentGeo?.name }</span></div>
          <InputSearchButton
            size='lg'
            placeholder='Введите название вашего города'
            onChange={ getSearch }
          />
          <div className={ styles.city__title }>Популярные города</div>
          <div className={ styles.city_filds }>
            { currentGeo?.id && (<div className={ cn(styles.city__field, styles.city__active) }>{ currentGeo.name }</div>) }
            { search && (search.map((item) => (
              <div
                key={ item.id }
                className={ styles.city__field }
                onClick={ () => onChangeCity({ id: item.id, name: item.name }) }
              >
                { item.name }
              </div>
            ))) }
            { !search && (defaultCity.map((item) => (
              <div
                key={ item.id }
                className={ styles.city__field }
                onClick={ () => onChangeCity({ id: item.id, name: item.name }) }
              >
                { item.name }
              </div>
            ))) }
          </div>
        </div>
      </Modal>
    </div>
  );
});
