import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  title: string;
  className?: string;
};

export const Marker: FC<Props> = ({ title, className }) => (
  <div className={ classNames(styles.marker, className) } dangerouslySetInnerHTML={ { __html: title } } />
);