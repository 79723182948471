import React, { useState } from 'react';
import { SuccessfullySent } from 'components/modal';
import { Input, TextArea } from 'components/ui/form';
import Modal from 'components/ui/modal';
import { schema } from 'form-helpers/questionToExpert/schema';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SendQuestionToExpert } from 'store/questionToExpert';
import { TQuestionToExpert } from 'types/form';
import ava from 'assets/images/ava_expert.png';
import styles from './styles.module.scss';


type Props = {
  open: boolean;
  onClose: () => void;
};

export const QuestionToExpertModal = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.emailQuestionToExpert);
  const [successOpen, setSuccessOpen] = useState(false);
  const modalClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Modal
        open={ open }
        onCancel={ modalClose }
        className={ styles.modal }
      >
        <div className={ styles.title }>Напишите вопрос и введите номер <br /> телефона</div>
        <div className={ styles.info }>
          <img src={ ava } />
          <div className={ styles.info__desc }>
            Ответит вам через 5 минут <br /> Юлия Мишина. Эксперт по <br /> сертификации. Опыт более 5 лет
          </div>
        </div>
        <Formik<TQuestionToExpert>
          initialValues={ {
            contacts: '',
            message: '',
            communication: {
              whatsap: false,
              telegram: false,
              viber: false,
            },
            type_message: 'Задать вопрос эсперту',
          } }
          validationSchema={ schema }
          onSubmit={ (values, { setSubmitting, resetForm }) => {
            dispatch(SendQuestionToExpert(values)).unwrap()
              .then(() => {
                setSuccessOpen(true);
                setSubmitting(false);
                resetForm();
                modalClose();
              });
          } }
        >
          { ({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
            /* and other goodies */
          }) => {
            const handleChangeMessager = (name: string) => () => {
              setFieldValue(`communication[${name}]`, true);
            };

            return (
              <form
                className={ styles.form }
                onSubmit={ handleSubmit }
              >
                <Input
                  type='hidden'
                  id="type_message"
                  name='type_message'
                  value="Задать вопрос эсперту"
                />
                <TextArea
                  name='message'
                  placeholder='Напишите ваш вопрос'
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                  value={ values.message }
                  error={ !!errors.message }
                  errorText={ errors.message }
                />
                <InputMask
                  id='contacts'
                  value={ values.contacts }
                  mask='+7 (999) 999-99-99'
                  placeholder='Введите телефон'
                  className={ styles.input }
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                />
                <div className={ styles.communicationMethod }>
                  <div className={ styles.communicationMethod_title }>Предпочитаемый способ связи:</div>
                  <div className={ styles.communicationMethod_change }>
                    <input type='checkbox' id='whatsap' name='whatsap' value={ values.communication.whatsap } />
                    <label htmlFor="whatsap" onClick={ handleChangeMessager('whatsap') } className={ styles.whatsap }><i className='icon-whatsapp' /></label>
                    <input type='checkbox' id='telegram' name='telegram' value={ values.communication.telegram } />
                    <label htmlFor="telegram" onClick={ handleChangeMessager('telegram') } className={ styles.telegram }><i className='icon-telegram' /></label>
                    <input type='checkbox' id='viber' name='viber' value={ values.communication.viber } />
                    <label htmlFor="viber" onClick={ handleChangeMessager('viber') } className={ styles.viber }><i className='icon-viber' /></label>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={ isSubmitting || Boolean(errors.contacts) || loading }
                  className={ styles.btn }
                >
                  Задать вопрос эксперту
                </button>
              </form>
            );
          } }
        </Formik>
        <p className={ styles.agreement }>Нажимая кнопку, вы даете согласие на <b>обработку персональных данных</b></p>
      </Modal>
      <SuccessfullySent
        open={ successOpen }
        onCancel={ () => setSuccessOpen(false) }
        title="Спасибо! Ваша заявка успешно отправлена, мы <br /> свяжемся с вами в течение 10 мин."
      />
    </React.Fragment>
  );
};