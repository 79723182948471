import React from 'react';

type Props = {
  name: string;
  style?: React.CSSProperties;
  className?: string;
};

export const Svg = ({ name, ...attr }: Props) => {
  const url = `/sprite.svg#${name}`;

  return (
    <svg { ...attr }>
      <use xlinkHref={ url } />
    </svg>
  );
};
