/* eslint-disable no-extra-boolean-cast */
import React, { FC } from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';

export const eventYm = (methodName: string, ...args: any[]) => {
  const enable = process.env.REACT_APP_PRODUCTION;

  if (enable !== 'false') {
    ym(methodName, args);
  }
};

export const EventInit: FC = () => {
  const enable = process.env.REACT_APP_PRODUCTION;

  if (enable !== 'false') {
    return (
      <YMInitializer
        accounts={ [Number(process.env.REACT_APP_YM)] }
        options={ {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        } }
        version="2"
      />
    );
  }

  return <div />;
};
