import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import type { PayloadAction } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import { TAccreditation } from 'types/accreditation';
import * as Api from 'types/api';
import { Pagination, Sort } from 'types/pagination';

export const getAccreditation = createAsyncThunk('accreditation/getAccreditation', async (payload: Api.GetAccreditationPayload, { getState }) => {
  const { accreditation } = getState() as RootState;

  const response = await fetchers.getAccreditation({
    pagination: {
      ...payload.pagination,
      sortOrder: accreditation.pagination.sortOrder,
    },
  });

  return response.data;
},
);

// Define a type for the slice state
interface AppState {
  loading: {
    get: boolean;
  };
  items: TAccreditation[] | null;
  pagination: Pagination & Sort;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: {
    get: false,
  },
  items: null,
  pagination: {
    page: 1,
    size: 8,
    itemCount: 0,
    pageSize: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    sortField: 'createdAt',
    sortOrder: 'asc',
  },
};

export const accreditationSlice = createSlice({
  name: 'accreditation',
  initialState,
  reducers: {
    clearAccreditation: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAccreditation.pending, (state) => {
      state.loading.get = true;
    });
    builder.addCase(getAccreditation.rejected, (state) => {
      state.loading.get = false;
    });
    builder.addCase(getAccreditation.fulfilled, (state, action) => {
      state.loading.get = false;
      state.items = action.payload.data;
      state.pagination = action.payload.pagination;
    });
  },
});

// export const { setLoading, getService, setService } = accreditationSlice.actions;
export const selectAccreditation = (state: RootState) => state.accreditation.items;
export const selectAccreditationPagination = (state: RootState) => state.accreditation.pagination;
export const selectAccreditationLoading = (state: RootState) => state.accreditation.loading;

export default accreditationSlice.reducer;