import cn from 'classnames';
// eslint-disable-next-line import/order
import { Swiper, SwiperSlide } from 'swiper/react';
import { MyImage } from 'components/ui/image';
import { A11y, Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import styles from './styles.module.scss';

type TData = {
  href: string;
};

const data: TData[] = [
  { href: '/images/slider1.jpg' },
  { href: '/images/slider2.jpg' },
  { href: '/images/slider3.jpg' },
  { href: '/images/slider4.jpg' },
];

type Props = {
  uid: string;
};

export const Slider = ({ uid }: Props) => {
  const swiperId = `swiper_${uid}`;


  return (
    <div className={ styles.slider }>
      <div className={ styles.container }>
        <div className={ styles.columns }>
          <p className={ styles.heading }>
            Наша аккредитованная лаборатория
          </p>
          <Swiper
            id={ swiperId }
            className={ styles.swiper }
            modules={ [Navigation, A11y, Autoplay] }
            spaceBetween={ 0 }
            slidesPerView={ 1 }
            autoplay={ { delay: 5000 } }
            navigation={ {
              prevEl: `.slider_swiper_${uid}_prev`,
              nextEl: `.slider_swiper_${uid}_next`,
            } }
          >
            { data && data.map(({ href }, i) => (
              <SwiperSlide key={ i }>
                <div
                  itemScope={ true }
                  itemType="https://schema.org/ImageObject"
                >
                  <MyImage src={ href } width='100%' height='400px' alt='' itemProp="contentUrl" />
                </div>
              </SwiperSlide>
            )) }
          </Swiper>
          <div className={ styles.navigation }>
            <div className={ cn(styles.navigation__prev, `slider_swiper_${uid}_prev`) }><i className='icon-angle-left' /></div>
            <div className={ cn(styles.navigation__next, `slider_swiper_${uid}_next`) }><i className='icon-angle-right' /></div>
          </div>
        </div>
      </div>
    </div>
  );
};
