import React from 'react';
import cn from 'classnames';
import { Button } from 'components/ui/button';
import { MyImage } from 'components/ui/image';
import Link from 'components/ui/link';
import { NavLink } from 'react-router-dom';
import { router } from 'routers/router';
import styles from './styles.module.scss';


const Сertificate = () => {
  return (
    <React.Fragment>
      <div className={ cn([styles.container, 'container', 'section-mt']) }>
        <div className={ styles.head }>
          <h2>Группа компаний Дельта аккредитованы</h2>
          <NavLink to={ router.accreditation.path }><Button color="darken" ttu={ true }>посмотреть все аттестаты</Button></NavLink>
        </div>
        <div className={ styles.content }>
          <div className={ styles.certificateInfo }>
            <div className={ styles.certificateInfo__txt }>Испытательная лаборатория “Центр испытаний машин и оборудования” <br /> ООО “ИЛ 73”</div>
            <div className={ styles.accreditationCertificate }>Аттестат аккредитации: № RA.RU.21ОМ18</div>
            <div>ОГРН: 1197325004810 <br /> ИНН: 7327090580</div>
          </div>
          <Link href="https://pub.fsa.gov.ru/ral/view/34605/applicant">Проверить на сайте ФСА</Link>
        </div>
        <div className={ styles.image }>
          <MyImage src='/images/certificate.jpg' width='515px' height='363px' zoom={ true } alt='Аттестат акредитации' />
        </div>
      </div>
      <div className={ cn([styles.infoWrap, 'section-mt', 'section-pb']) }>
        <div className='container'>
          <div className={ styles.info }>
            <div className={ styles.infoContent }>
              <div className={ styles.infoTitle }>на сегодняшний день мы выполнили:</div>
              <div className={ styles.infoList }>
                <div className={ styles.infoItem }>
                  <div className={ styles.infoItemValue }>37585</div>
                  <div className={ styles.infoItemTitle }>сертификатов</div>
                </div>
                <div className={ styles.infoItem }>
                  <div className={ styles.infoItemValue }>12546</div>
                  <div className={ styles.infoItemTitle }>деклараций </div>
                </div>
                <div className={ styles.infoItem }>
                  <div className={ styles.infoItemValue }>45555</div>
                  <div className={ styles.infoItemTitle }>испытаний </div>
                </div>
              </div>
            </div>
            <div className={ styles.infoSuccess }>успешно</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Сertificate;
