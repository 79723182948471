import { useEffect } from 'react';
import { PromotionItem } from 'components/promotion';
import { AppLayout, AppNavigation } from 'components/ui/app';
import { Button } from 'components/ui/button';
import { PromotionSkeleton } from 'components/ui/skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { router } from 'routers/router';
import { useAppDispatch } from 'store/hooks';
import { clearPromotionsDiscount, getPromotionsDiscount, selectPromotionsDiscount, selectPromotionsDiscountLoading } from 'store/promotionsDiscount';
import styles from './styles.module.scss';

export const Promotiondiscounts = () => {
  const dispatch = useAppDispatch();
  const promo = useSelector(selectPromotionsDiscount);
  const loading = useSelector(selectPromotionsDiscountLoading);

  useEffect(() => {
    dispatch(getPromotionsDiscount({ pagination: { page: 1, size: 30 } }));

    return () => {
      dispatch(clearPromotionsDiscount());
    };
  }, [dispatch]);

  return (
    <AppLayout flex={ true }>
      <AppNavigation />

      <div className={ styles.grid }>
        { loading.get ? Array(3).fill(null).map((_, i) => (
          <PromotionSkeleton key={ i } />
        )) : (
          promo?.map((item, i) => (
            <PromotionItem data={ item } key={ i } />
          ))
        ) }
      </div>

      <div className="section-mb">
        <Link to={ router.main.path }>
          <Button
            color='redDarken'
            ttu={ true }
            className={ styles.btnDelta }
          >
            оформить сертификацию
          </Button>
        </Link>
      </div>
    </AppLayout>
  );
};
