import React, { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import styles from './styles.module.scss';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  width: string;
  alt: string;
  zoom?: boolean;
}

export const MyImage = ({ src, zoom = false, width = '100%', height = '100%', alt, ...props }: Props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const img = new Image();

    img.src = src;
    img.onload = () => {
      setLoading(true);
    };
  }, [src]);

  if (!loading) {
    return (<div className={ styles.skeleton } style={ { width, height } } />);
  }

  if (zoom) {
    return (
      <Zoom>
        <img src={ src } width={ width } alt={ alt || '' } { ...props } />
      </Zoom>
    );
  }

  return (
    <img src={ src } width={ width } alt={ alt || '' } { ...props } />
  );
};
