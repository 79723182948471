// Выводим на главнйо странице
import React, { useState } from 'react';
import { data } from './data';
import styles from './styles.module.scss';
import { PriceHeaderItem } from '../item/headerItem';


export const PriceForMain = () => {

  const [list, setList] = useState(data.slice(0, 3));


  const onNext = () => {
    setList(data);
  };
  const onCloseList = () => {
    setList(data.slice(0, 3));
  };

  return (
    <section>
      <div className="container">
        <div className="columns">
          <div className="heading">
            <h2>Цены</h2>
          </div>
        </div>
        <div className={ styles.prices }>
          { list.map((item, i) => (<PriceHeaderItem data={ item } key={ i } />)) }
          { list.length > 3 ? (
            <div
              className={ styles.next }
              onClick={ onCloseList }
            >
              Скрыть
            </div>
          ) : (
            <div
              className={ styles.next }
              onClick={ onNext }
            >
              Показать ещё
            </div>
          ) }
        </div>
      </div>
    </section>
  );
};