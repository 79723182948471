import { IS_EMAIL, REQUIRED_FIELD } from 'form-helpers/message';
import * as yup from 'yup';
import 'yup-phone';

export const schema = yup.object().shape({
  authorName: yup.string().required(REQUIRED_FIELD),
  authorEmail: yup.string().email(IS_EMAIL).required(REQUIRED_FIELD),
  desc: yup.string().required(REQUIRED_FIELD),
  image: yup.array().nullable(),
});
