import React from 'react';
import cn from 'classnames';
import Banner from 'components/banner';
import Сertificate from 'components/certificate';
import Confidence from 'components/confidence';
import DocumentsForConfirmation from 'components/documentsForConfirmation';
import { ImageBannerLeft, ImageBannerRight } from 'components/imageBanner';
import { PriceForMain } from 'components/price';
import { ProductsWeCertify } from 'components/productsWeCertify';
import { Quiz } from 'components/quiz';
import Reviews from 'components/reviews';
import { ServicesMain } from 'components/services';
import { Slider } from 'components/slider';
import styles from 'styles/base.module.scss';
import avacris from 'assets/images/avacris.jpg';
import redBasket from 'assets/images/icons/red-basket.svg';
import redGlobe from 'assets/images/icons/red-globe.svg';
import redLamp from 'assets/images/icons/red-lamp.svg';
import mainDelta from 'assets/images/main-delta.png';
import mainDelta2 from 'assets/images/main-delta2.jpg';


export const Main = () => {
  return (
    <React.Fragment>
      <Banner title='Сертификат соответствия на вашу продукцию и бесплатная консультация эксперта' />
      <ServicesMain />
      <ImageBannerRight
        dedicated='5 собственных аккредитованных лабораторий'
        title='Дельта-Сертификат — это центр сертификации'
        description='Все самые нужные услуги в одном месте'
        marker={ [
          'Цены на 15% ниже  конкурентов',
          'Более 350 методик в области аккредитации',
          'Быстрое оформление протоколов',
          'Инженеры испытатели с опытом от 10 лет',
        ] }
        contact={ {
          job: 'Инженер-испытатель',
          name: 'Кристина Крылова',
          ava: avacris,
        } }
        feedback={ {
          title: '8 (800) 777-61-58',
          url: 'tel:8 (800) 777-61-58',
        } }
        image={ mainDelta }
      />
      <ImageBannerLeft
        dedicated='эксперты в своем деле'
        title='Аккредитованные органы по сертификации'
        marker={ [
          {
            title: 'Оформляйте документы через удобный личный кабинет',
            description: 'Отслеживайте весь процесс сертификации на сайте или менеджер подготовит все документы за вас',
          },
          {
            title: 'Подробно и понятно обяъсняем про сертификацию для маркетплейсов',
            description: 'Высылаем чел-лист с инструкциями. Подробная консультация в мессенджерах и по телефону',
          },
        ] }
        listBlok={ {
          title: 'Проводим сертификацию для:',
          child: [
            {
              img: redLamp,
              title: 'Производителей продукции',
            },
            {
              img: redGlobe,
              title: 'Поставщиков товаров из Китая',
            },
            {
              img: redBasket,
              title: 'Продавцов на маркетплейсах',
            },
          ],
        } }
        image={ mainDelta2 }
      />
      <div className={ styles.bgBase }>
        <Slider uid="app" />
        <div className={ cn(styles.smb, styles.spb, 'container') }>
          <div className="columns">
            <div className="heading">
              <h2>Рассчитайте стоимость  сертификации за 30 секунд</h2>
              <p className='heading_description'>Ответьте на 5 вопросов, а наши эксперты подберут вам необходимый документ и дадут рекомендации</p>
            </div>
            <Quiz />
          </div>
        </div>
      </div>
      <ProductsWeCertify />
      <PriceForMain />
      <DocumentsForConfirmation />
      <Confidence className='section-mb' />
      <Reviews />
      <Сertificate />
    </React.Fragment>
  );
};
