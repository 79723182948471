import React, { useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  data: string[];
  limit?: number;
};

export const ListOfParameters = ({ data, limit = 10 }: Props) => {
  const [view, setView] = useState(false);
  const [items, setItems] = useState(data.slice(0, limit));
  const size = data.length;

  const handleViewAllItems = () => {
    if (view) {
      setItems(data.slice(0, limit));
      setView(false);
    } else {
      setItems(data);
      setView(true);
    }
  };

  return (
    <React.Fragment>
      <div className={ styles.listOfParameters }>
        { items.map((item) => <div key={ item }>{ item }</div>) }
      </div>
      { size > limit ? (<div className={ styles.link } onClick={ handleViewAllItems }>{ view ? 'Скрыть список' : 'Показать весь список' }</div>) : null }
    </React.Fragment>
  );
};