import React, { useState } from 'react';
import { SuccessfullySent } from 'components/modal';
import { Input, TextArea } from 'components/ui/form';
import Modal from 'components/ui/modal';
import { eventYm } from 'components/ui/yandex-metrika';
import { schema } from 'form-helpers/feedback/schema';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { selectFeedbackLoading, sendFeedback } from 'store/feedback';
import { useAppDispatch } from 'store/hooks';
import { TFeedbackModal } from 'types/form';
import styles from './styles.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const FeedbackModal = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectFeedbackLoading);
  const [successOpen, setSuccessOpen] = useState(false);

  const modalClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Modal
        open={ open }
        onCancel={ modalClose }
        className={ styles.modal }
      >
        <div className={ styles.title }>Обратная связь</div>
        <Formik<TFeedbackModal>
          initialValues={ {
            type_message: 'Обратная связь',
            name: '',
            contacts: '',
            city: '',
            message: '',
          } }
          validationSchema={ schema }
          onSubmit={ (values, { resetForm, setSubmitting }) => {
            dispatch(sendFeedback(values)).unwrap()
              .then(() => {
                setSuccessOpen(true);
                setSubmitting(false);
                resetForm();
                eventYm('reachGoal', 'feedback');
                modalClose();
              });
          } }
        >
          { ({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            errors,
            /* and other goodies */
          }) => (
            <form
              className={ styles.form }
              onSubmit={ handleSubmit }
            >
              <Input
                id="name"
                name='name'
                placeholder="Ваше имя"
                value={ values.name }
                onChange={ handleChange }
                onBlur={ handleBlur }
                error={ !!errors.name }
                errorText={ errors.name }
              />
              <Input
                id="contacts"
                name='contacts'
                placeholder="Email или телефон"
                value={ values.contacts }
                onChange={ handleChange }
                onBlur={ handleBlur }
                error={ !!errors.contacts }
                errorText={ errors.contacts }
              />
              <Input
                id="city"
                name='city'
                placeholder="Ваш город"
                value={ values.city }
                onChange={ handleChange }
                onBlur={ handleBlur }
                error={ !!errors.city }
                errorText={ errors.city }
              />
              <TextArea
                id="message"
                name='message'
                placeholder="Опишите ваш вопрос"
                autoSize={ true }
                className={ styles.textarea }
                value={ values.message }
                onChange={ handleChange }
                onBlur={ handleBlur }
                error={ !!errors.message }
                errorText={ errors.message }
              />
              <button
                type="submit"
                disabled={ isSubmitting || loading }
                className={ styles.btn }
              >
                Отправить
              </button>
            </form>
          ) }
        </Formik>
        <p className={ styles.info }>Нажимая кнопку, вы даете согласие на <b>обработку персональных данных</b></p>
      </Modal>
      <SuccessfullySent
        open={ successOpen }
        onCancel={ () => setSuccessOpen(false) }
        title="Спасибо! Ваша заявка успешно отправлена, мы <br /> свяжемся с вами в течение 10 мин."
      />
    </React.Fragment>
  );
};