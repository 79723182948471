import React from 'react';
import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { AppLayout } from 'components/ui/app';
import { AppPageNotFound } from 'components/ui/notFound';

export const PageNotFound = () => {
  return (
    <React.Fragment>
      <Header />
      <AppLayout flex={ true }>
        <AppPageNotFound />
      </AppLayout>
      <Footer />
    </React.Fragment>

  );
};
