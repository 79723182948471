import { Fragment } from 'react';
import cn from 'classnames';
import { AppLayout, AppNavigation } from 'components/ui/app';
import styles from './styles.module.scss';

const list = [
  {
    icon: <i className={ cn('icon-phone', styles.iphone) } />,
    title: 'Позвоните нам',
    desc: 'Есть вопросы? <br/> Мы поможем!',
    contact: [
      {
        phone: '8 (495) 108-32-91',
        desc: 'для Москвы',
      },
      {
        phone: '8 (800) 777-61-58',
        desc: 'для Регионов',
      },
    ],
  },
  {
    icon: <i className={ cn('icon-email', styles.iemail) } />,
    title: 'Напишите нам',
    desc: 'Напишите нам вопросы и предложения',
    contact: [{
      email: 'info@delta-certificate.com',
    }],
  },
  {
    icon: <span className={ cn(styles.ibg, styles.viber) }><i className='icon-viber' /></span>,
    title: 'Напишите нам',
    desc: 'В Viber чат',
    contact: [{
      link: 'viber://chat?number=+79176040396',
      title: 'Перейти в Viber',
    }],
  },
  {
    icon: <span className={ cn(styles.ibg, styles.whatsapp) }><i className='icon-whatsapp' /></span>,
    title: 'Напишите нам',
    desc: 'В WhatsApp чат',
    contact: [{
      link: 'https://wa.me/+79176040396',
      title: 'Перейти в WhatsApp',
    }],
  },
  {
    icon: <span className={ cn(styles.ibg, styles.telegram) }><i className='icon-telegram' /></span>,

    title: 'Напишите нам',
    desc: 'В Telegram чат',
    contact: [{
      link: 'tg://resolve?domain=Delta_certificate',
      title: 'Перейти в Telegram',
    }],
  },
];

export const Contacts = () => (
  <AppLayout flex={ true }>
    <AppNavigation />
    <h2 className={ styles.heading }>Контактные данные</h2>

    <div className={ styles.info }>
      <div className={ styles.info_name }>
        ООО "ДЕЛЬТА-СЕРТИФИКАТ"
      </div>
      <div className={ styles.info__item }>
        <div className={ styles.info__name }>ОГРН:</div>
        <div className={ styles.info__value }>1177746576314</div>
      </div>
      <div className={ styles.info__item }>
        <div className={ styles.info__name }>ИНН:</div>
        <div className={ styles.info__value }>7722403820</div>
      </div>
    </div>

    <div className={ styles.contact }>
      { list.map((item, i) => (
        <div className={ styles.contact_block } key={ i }>
          <div className={ styles.contact_title }>{ item?.icon } <div>{ item.title }</div></div>
          <div className={ styles.contact__text } dangerouslySetInnerHTML={ { __html: item.desc } } />
          { item.contact.map((sub: any, s) => (
            <Fragment key={ s }>
              { sub.link && (<a href={ `${sub.link}` } target="_blank" className={ styles.contact__link }>{ sub.title }</a>) }
              { sub.phone && (<a href={ `tel:${sub.phone}` } className={ styles.contact__phone }>{ sub.phone }</a>) }
              { sub.email && (<a href={ `mailto:${sub.email}` } className={ styles.contact__link }>{ sub.email }</a>) }
              { sub.desc && (<div className={ styles.contact__desc }>{ sub.desc }</div>) }
            </Fragment>
          )) }
        </div>
      )) }
    </div>

    <p className='section-mb' />
  </AppLayout>
);