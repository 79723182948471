
type TQuestionValue = {
  img?: string;
  value: string;
};

type TQuestion = {
  title: string;
  variants: TQuestionValue[];
};

export const questions: TQuestion[] = [
  {
    title: 'Какой тип разрешительного документа необходим?',
    variants: [
      {
        img: '/images/quiz1.jpg',
        value: 'Сертификат соответствия',
      },
      {
        img: '/images/quiz2.jpg',
        value: 'Сертификат Таможеного союза',
      },
      {
        img: '/images/quiz3.jpg',
        value: 'Декларация соответствия',
      },
      {
        img: '/images/quiz4.jpg',
        value: 'Пожарный сертификат',
      },
      {
        value: 'Другое',
      },
      {
        value: 'Нужна помощь эксперта',
      },
    ],
  },
  {
    title: 'Укажите тип продукции',
    variants: [
      {
        value: 'Пищевая продукция',
      },
      {
        value: 'Оборудование',
      },
      {
        value: 'Одежда',
      },
      {
        value: 'Мебель',
      },
      {
        value: 'Товары для Wildberries и Озон',
      },
      {
        value: 'Другое',
      },
    ],
  },
  {
    title: 'Когда нужно получить документы?',
    variants: [
      {
        value: 'Как можно раньше',
      },
      {
        value: 'Неважно',
      },
      {
        value: 'В течение недели',
      },
      {
        value: 'В течение месяца',
      },
      {
        value: 'Другое',
      },
    ],
  },
  {
    title: 'Оформляли ли вы ранее разрешительные документы?',
    variants: [
      {
        value: 'Да',
      },
      {
        value: 'Неважно',
      },
      {
        value: 'В течение недели',
      },
      {
        value: 'В течение месяца',
      },
      {
        value: 'Другое',
      },
    ],
  },
];
