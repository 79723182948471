import { memo } from 'react';
import cn from 'classnames';
import { ListSlider } from 'components/company';
import Confidence from 'components/confidence';
import { AppLayout, AppNavigation } from 'components/ui/app';
import styles from './styles.module.scss';

export const Company = memo(() => {
  return (
    <AppLayout>
      <div className="container">
        <div className="columns">
          <AppNavigation />
          <h2 className={ styles.heading }>О Дельта-Сертификат</h2>
          <p className={ styles.desc }>
            Группа компаний “Дельта” — экспертный центр сертификации в России. <br />
            Сертифицируйте продукцию, товары и оборудование в одном месте.
          </p>
        </div>
      </div>
      <ListSlider />
      <div className={ styles.bg }>
        <Confidence className='padding-mt' />
      </div>
      <div className={ cn([styles.infoWrap, 'section-pt']) }>
        <div className='container'>
          <div className={ styles.info }>
            <div className={ styles.infoContent }>
              <div className={ styles.infoTitle }>на сегодняшний день мы выполнили:</div>
              <div className={ styles.infoList }>
                <div className={ styles.infoItem }>
                  <div className={ styles.infoItemValue }>37585</div>
                  <div className={ styles.infoItemTitle }>сертификатов</div>
                </div>
                <div className={ styles.infoItem }>
                  <div className={ styles.infoItemValue }>12546</div>
                  <div className={ styles.infoItemTitle }>деклараций </div>
                </div>
                <div className={ styles.infoItem }>
                  <div className={ styles.infoItemValue }>45555</div>
                  <div className={ styles.infoItemTitle }>испытаний </div>
                </div>
              </div>
            </div>
            <div className={ styles.infoSuccess }>успешно</div>
          </div>
        </div>
      </div>
      <div className="section-mb" />
    </AppLayout>
  );
});
