import styles from './styles.module.scss';


type Props = {
  title: string;
};

export const PriceHeader = ({ title }: Props) => (
  <div className={ styles.header }>
    { title }
  </div>
);


