// import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { Button } from '../button';

export const AppPageNotFound = () => {
  return (
    <div className={ styles.section }>
      <h2 className={ styles.pmb30 }>Ой!</h2>
      <h3 className={ styles.h3 }>Похоже, мы не можем найти нужную вам страницу.</h3>
      <h3 className={ styles.pmb30 }>Код ошибки: 404</h3>
      <p className={ styles.pmb30 }>Перейдите на нашу главную страницу и попробуйте найти необходимую вам информацию там.</p>
      <Link to='/'><Button color="darken" ttu={ true }>ПЕРЕЙТИ НА ГЛАВНУЮ</Button></Link>
    </div>
  );
};
