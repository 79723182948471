import React from 'react';
import { Link } from 'react-router-dom';
import cl from './styles.module.scss';

type TMenu = {
  menu: Array<{
    path: string;
    name: string;
    element: any;
  }>;
};

export const MobileMenu = React.memo(({ menu }: TMenu) => {
  return (
    <div className={ cl.container }>
      <div
        className={ cl.menu }
        itemScope={ true }
        itemType="http://schema.org/SiteNavigationElement"
      >
        { menu && menu.map((item) => (
          <div
            key={ item.path }
            className={ cl.menu__link }
            itemProp="itemListElement"
            itemScope={ true }
            itemType="http://schema.org/ItemList"
          >
            <Link to={ item.path } itemProp="url">{ item.name }</Link>
            <meta itemProp="name" content={ item.name } />
          </div>
        )) }
      </div>
    </div>
  );
});
