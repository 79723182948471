import { useEffect } from 'react';
import { AccreditationBlock } from 'components/accreditation';
import { AppLayout, AppNavigation } from 'components/ui/app';
import { Button } from 'components/ui/button';
import { AccreditationSkeleton } from 'components/ui/skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { router } from 'routers/router';
import {
  getAccreditation, selectAccreditation,
  selectAccreditationLoading,
} from 'store/accreditation';
import { useAppDispatch } from 'store/hooks';
import styles from './styles.module.scss';

export const Accreditation = () => {
  const dispatch = useAppDispatch();
  const accreditation = useSelector(selectAccreditation);
  const loading = useSelector(selectAccreditationLoading);

  useEffect(() => {
    dispatch(getAccreditation({ pagination: { page: 1, size: 30 } }));
  }, [dispatch]);

  return (
    <AppLayout flex={ true }>
      <AppNavigation />
      <h1 className={ styles.heading }>Аттестаты аккредитации</h1>
      <div className={ styles.desc }>Сертификация от аккредитованных органов и лабораторий<br /> и деклараций соответствия по самым выгодным условиям</div>
      <div className={ styles.grid }>
        { loading.get ? Array(3).fill(null).map((_, i) => (
          <AccreditationSkeleton key={ i } />
        )) : (
          accreditation?.map((item, i) => (
            <AccreditationBlock data={ item } key={ i } />
          ))
        ) }
      </div>
      <div className="section-mb">
        <Link to={ router.main.path }>
          <Button
            color='redDarken'
            ttu={ true }
            className={ styles.btn }
          >
            оформить сертификацию
          </Button>
        </Link>
      </div>
    </AppLayout>
  );
};
