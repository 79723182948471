import React, { useState } from 'react';
import cn from 'classnames';
import { FeedbackModal } from 'components/form';
import { EventInit } from 'components/ui/yandex-metrika';
import { Link } from 'react-router-dom';
import { routerFooter } from 'routers/router';
import imgVk from 'assets/images/footer/vk.png';
import footerLogo from 'assets/images/footerLogo.svg';
import styles from './styles.module.scss';

export const Footer = React.memo(() => {
  const [open, setOpen] = useState(false);
  const phone = '8 (800) 777-61-58';
  const email = 'info@delta-certificate.com';

  return (
    <div className={ styles.footer } itemType="https://schema.org/WPFooter">
      <meta itemProp="copyrightYear" content="2022"></meta>
      <div className={ cn(styles.top, 'container') }>
        <div className={ cn(styles.top_columns, 'row') }>
          <a className={ styles.logo }><img src={ footerLogo } alt='' /></a>
          <div className={ styles.flexGrow } />
          <div className={ styles.contact } itemScope={ true } itemType="http://schema.org/Organization">
            <a href={ `tel:${phone}` } className={ styles.contact_phone } itemProp="telephone">{ phone }</a>
            <a href={ `mailto:${email}` } className={ styles.contact_email } itemProp="email">{ email }</a>
          </div>
        </div>
      </div>
      <div className={ cn(styles.middle, 'container') }>
        <div className={ cn(styles.middle_columns, 'row') }>
          <ul className={ styles.menu }>
            { routerFooter && routerFooter.map((item, i) => (
              <li key={ i }><Link to={ item.path }>{ item.name }</Link></li>
            )) }
          </ul>
        </div>
      </div>
      <div className={ cn(styles.bottom, 'container') }>
        <div className={ cn(styles.middle_columns, 'row') }>
          <div className={ styles.feedback_form }>
            <div className={ styles.feedback__btn } onClick={ () => setOpen(true) }>
              <i className='icon-feedback' />
              <span>Форма обратной связи</span>
            </div>
            <div className={ styles.feedback_text }>
              Пишите нам свои вопросы, <br />
              комментарии и предложения
            </div>
          </div>
        </div>
      </div>
      <div className={ styles.info }>
        <div />
        <div className={ styles.siteRights }>© 2010 – 2022 ООО «Дельта-Сертификат». Все права защищены.</div>
        <div className={ styles.social }>
          <a href="https://vk.com/deltacertificate" target="_blank"><img src={ imgVk } alt="" /></a>
          { /* <a href="#"><img src={ imgInstagram } alt="" /></a> */ }
          { /* <a href="#"><img src={ imgYoutube } alt="" /></a> */ }
        </div>
      </div>
      <div className={ styles.mContact }>
        <a href='tel:8 (800) 777-61-58' className={ styles.mContact__phone }>
          <i className='icon-phone' />
          <span>ПОЗВОНИТЬ</span>
        </a>
        <a className={ styles.mContact__whatsap } href="https://wa.me/79170617036?text=Здравствуйте%2C+у+меня+есть+вопрос" target="_blank">
          <i className='icon-whatsapp' />
          <span>
            НАПИСАТЬ В WHATSAPP
          </span>
        </a>
      </div>
      <FeedbackModal open={ open } onClose={ () => setOpen(false) } />
      <EventInit />
    </div>
  );
});
