import { Suspense } from 'react';
import cn from 'classnames';
import Banner from 'components/banner';
import Сertificate from 'components/certificate';
import Confidence from 'components/confidence';
import DocumentsForConfirmation from 'components/documentsForConfirmation';
import { NewsSlider } from 'components/news';
import { Quiz } from 'components/quiz';
import Reviews from 'components/reviews';
import { Slider } from 'components/slider';
import styles from 'styles/base.module.scss';

export const DeclarationOfСonformity = () => {
  return (
    <Suspense>
      <div className="App" >
        <Banner title='Быстро оформим декларацию соответствия ' />
        <div className="container">
          <div className="flex-row-column">
            <div className="heading">
              <h2>Дельта-Сертификат — это  центр сертификации</h2>
              <p className='heading_description'>В состав Дельты входит испытательный центр  с рядом профильных лабораторий</p>
            </div>
          </div>
        </div>
        <NewsSlider />
        <div className={ styles.bgBase }>
          <Slider uid="app" />
          <div className={ cn(styles.smb, styles.spb, 'container') }>
            <div className="columns">
              <div className="heading">
                <h2>Рассчитайте стоимость  сертификации за 30 секунд</h2>
                <p className='heading_description'>Ответьте на 5 вопросов, а наши эксперты подберут вам необходимый документ и дадут рекомендации</p>
              </div>
              <Quiz />
            </div>
          </div>
        </div>
        <DocumentsForConfirmation />
        <Confidence className='section-mb' />
        <Reviews />
        <Сertificate />
      </div>
    </Suspense >
  );
};
