import { ListPlusWhite } from 'components/ui/list';
import { Marker } from 'components/ui/marker';
import styles from './styles.module.scss';

type Props = {
  title: string;
  description?: string;
  dedicated?: string;
  marker?: Array<{
    title: string;
    description: string;
  }>;
  listBlok?: {
    title: string;
    child: Array<{
      img: string;
      title: string;
    }>;
  };
  image: string;
};

export const ImageBannerLeft = ({ title, description, dedicated, marker, listBlok, image }: Props) => (
  <div className={ styles.container }>
    <div className={ styles.image }>
      <img src={ image } alt="" />
    </div>
    <div className={ styles.txt }>
      { dedicated && (<Marker className={ styles.dedicated } title={ dedicated } />) }
      <h2 className={ styles.title } dangerouslySetInnerHTML={ { __html: title } } />
      { description && (<div className={ styles.desc } dangerouslySetInnerHTML={ { __html: description } } />) }
      { marker && marker.length > 0 && (
        <div className={ styles.markers }>
          <ListPlusWhite
            className={ styles.marker }
            list={ marker }
          />
        </div>
      ) }
      <div className={ styles.contact }>
        <div className={ styles.contact__title }>
          { listBlok?.title }
        </div>
        <div className={ styles.contact__list }>
          { listBlok?.child.map((item, i) => (
            <div className={ styles.list } key={ i }>
              <div className={ styles.list__icon }>
                <img src={ item.img } alt="" />
              </div>
              <div className={ styles.list__title }>{ item.title }</div>
            </div>
          )) }
        </div>
      </div>
    </div>
  </div >
);