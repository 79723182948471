import { ListPlus } from 'components/ui/list';
import { Marker } from 'components/ui/marker';
import iconPhone from 'assets/images/icons/red-phone.svg';
import styles from './styles.module.scss';

type Props = {
  title: string;
  description?: string;
  dedicated?: string;
  marker?: string[];
  image: string;
  contact?: {
    job: string;
    name: string;
    ava: string;
  };
  feedback?: {
    title: string;
    url: string;
  };
};

export const ImageBannerRight = ({ title, description, dedicated, marker, contact, feedback, image }: Props) => (
  <div className={ styles.container }>
    <div className={ styles.txt }>
      { dedicated && (<Marker className={ styles.dedicated } title={ dedicated }  />) }
      <h2 className={ styles.title } dangerouslySetInnerHTML={ { __html: title } } />
      { description && (<div className={ styles.desc } dangerouslySetInnerHTML={ { __html: description } } />) }
      { marker && marker.length > 0 && (
        <div className={ styles.markers }>
          <ListPlus
            className={ styles.marker }
            list={ marker }
          />
        </div>
      ) }
      <div className={ styles.contact }>
        { contact && (
          <div className={ styles.info }>
            <div className={ styles.info__ava }>
              <img src={ contact.ava } alt="" />
            </div>
            <div className={ styles.info_content }>
              <div className={ styles.info__title }>{ contact.job }</div>
              <div className={ styles.info__name }>{ contact.name }</div>
            </div>
          </div>
        ) }
        { feedback && (
          <div className={ styles.info }>
            <div className={ styles.info__ava }>
              <img src={ iconPhone } alt="" />
            </div>
            <div className={ styles.info_content }>
              <div className={ styles.info__title }>Связаться</div>
              <a href={ feedback.url } className={ styles.info__name }>{ feedback.title }</a>
            </div>
          </div>
        ) }
      </div>
    </div>
    <div className={ styles.image }>
      <img src={ image } alt="" />
    </div>
  </div >
);