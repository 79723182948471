export const listOfParameters = [
  'Радиологический анализ',
  'Потенциометрия',
  'Вольтамперометрия',
  'Общие физико-химические методы',
  'Фотометрия',
  'Титрование',
  'Жидкостная хроматрография',
  'Иммуноферментный анализ',
  'Газовая хроматография',
  'Микробиологические исследования',
];

export const listOfSubstances = [
  {
    title: 'Добавки, ароматизаторы, технологические вспомогательные вещества;',
  },
  {
    title: 'Кондитерские изделия. Сахар, продукты сахарной промышленности;',
  },
  {
    title: 'Масложировая продукция;',
  },
  {
    title: 'Молоко и молочная продукция;',
  },
  {
    title: 'Мясо и мясная продукция;',
  },
  {
    title: 'Мясо птицы, яйца и продукты их переработки;',
  },
  {
    title: 'Напитки;',
  },
  {
    title: 'Плодоовощная продукция;',
  },
  {
    title: 'Альтернативные показатели по микробиологии для консервов, в том числе:',
    children: [
      { title: 'соковая продукция консервированная;' },
      { title: 'мясная продукция консервированная;' },
      { title: 'рыбная продукция консервированная; ' },
      { title: 'плодоовощная продукция консервированная' },
    ],
  },
  {
    title: 'Продукты переработки зерна, зерно продовольственное;',
  },
  {
    title: 'Рыбная продукция;',
  },
  {
    title: 'Соковая продукция;',
  },
  {
    title: 'Хлебобулочные изделия;',
  },
  {
    title: 'Сухари панировочные;',
  },
  {
    title: 'Изделия макаронные;',
  },
  {
    title: 'Материалы текстильные: бельевые (для постельного, нательного, столового белья, бельевых, корсетных и купальных изделий);',
  },
  {
    title: 'Материалы текстильные: полотенечные (для полотенец, простыней (купальных), гладких, жаккардовых, вафельных, махровых);',
  },
  {
    title: 'Материалы текстильные: одежные (плащевые и курточные, пальтовые, костюмные, платьево-костюмные, платьевые, блузочные, сорочечные, платочные и подкладочные);',
  },
  {
    title: 'Материалы текстильные: обувные (для верха и подкладки обуви);',
  },
  {
    title: 'Материалы текстильные: декоративные (для гардин, портьер, штор, покрывал, скатертей, накидок, дорожек, шезлонгов);',
  },
  {
    title: 'Материалы текстильные: мебельные (для обивки мебели, матрацев, чехольные);',
  },
  {
    title: 'Материалы текстильные: мех искусственный;',
  },
  {
    title: 'и ткани ворсовые (для верхних изделий, воротников, отделки, подкладки, головных уборов, декоративного назначения, в том числе пледов);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: изделия верхние (жакеты, джемперы, куртки, жилеты, костюмы, блузки, юбки, платья, сарафаны, шорты, комплекты, халаты, брюки, комбинезоны, рейтузы, костюмы и брюки спортивные и другие аналогичные изделия);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: изделия чулочно-носочные, имеющие непосредственный контакт с кожей человека (колготки, чулки, получулки, гетры, носки, легинсы, кюлоты, подследники и другие аналогичные изделия);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: изделия чулочно-носочные зимнего ассортимента, имеющие ограниченный контакт с кожей человека;',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: изделия перчаточные (перчатки, варежки, рукавицы и другие аналогичные изделия);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: изделия платочно-шарфовые (шарфы, платки, косынки);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: одежда верхняя (пальто, полупальто, плащи, куртки, куртки (брюки, костюмы) спортивные, комбинезоны, полукомбинезоны и другие аналогичные изделия);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: сорочки верхние;',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: изделия костюмные (костюмы, пиджаки, жакеты, юбки, жилеты, куртки типа пиджаков, брюки, шорты и другие аналогичные изделия);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: изделия плательные (платья (включая сарафаны, халаты), юбки, блузки, жилеты, фартуки, брючные комплекты и другие аналогичные изделия);',
  },
  {
    title: 'Одежда и изделия швейные и трикотажные: одежда домашняя (халаты, костюмы и другие аналогичные изделия);',
  },
  {
    title: 'Изделия бельевые: белье нательное;',
  },
  {
    title: 'Изделия бельевые: белье постельное, столовое и кухонное, полотенца;',
  },
  {
    title: 'Изделия бельевые: носовые платки;',
  },
  {
    title: 'Изделия бельевые: изделия купальные;',
  },
  {
    title: 'Изделия бельевые: изделия корсетные (бюстгальтеры, корсеты и другие аналогичные изделия);',
  },
  {
    title: 'Постельные принадлежности (одеяла, подушки и другие аналогичные изделия);',
  },
  {
    title: 'Головные уборы (фуражки, кепи, шапки, шляпы, панамы, береты, тюбетейки и другие аналогичные изделия);',
  },
  {
    title: 'Покрытия и изделия ковровые машинного способа производства:',
  },
  {
    title: '(ковры, дорожки ковровые, дорожки напольные, покрытия текстильные напольные);',
  },
  {
    title: 'Изделия текстильно-галантерейные (изделия гардинно-тюлевые, полотно кружевное и изделия кружевные, изделия штучные, галстуки, накидки, покрывала, шторы и другие аналогичные изделия);',
  },
  {
    title: 'Изделия кожгалантерейные: сумки, чемоданы, портфели, рюкзаки, саквояжи, портпледы, футляры, папки и другие аналогичные изделия;',
  },
  {
    title: 'Изделия кожгалантерейные: перчатки, рукавицы;',
  },
  {
    title: 'Изделия кожгалантерейные: ремни поясные, для часов и другие аналогичные изделия;',
  },
  {
    title: 'Войлок, фетр и нетканые материалы (войлок, фетр и нетканые материалы);',
  },
  {
    title: 'Обувь (сапоги, полусапоги, сапожки, полусапожки, ботинки, полуботинки, туфли, галоши и другие виды обуви из натуральной, искусственной и синтетической кожи, обуви резиновой, резинотекстильной, валяной, комбинированной, из текстильных, полимерных и других материалов);',
  },
  {
    title: 'Кожа искусственная (для верха и подкладки обуви, для одежды и головных уборов, перчаток и рукавиц, галантерейная, мебельная и для обивки различных изделий);',
  },
  {
    title: 'Кожа искусственная: кожа для низа, верха и подкладки изделий, галантерейная;',
  },
  {
    title: 'Кожа искусственная: для перчаток и рукавиц;',
  },
  {
    title: 'Кожа искусственная: для обивки мебели и другие виды кож;',
  },
  {
    title: 'Одежда, головные уборы и другие изделия из кожи;',
  },
  {
    title: 'Пальто, полупальто, куртки, накидки, костюмы, жилеты, головные уборы, воротники, манжеты, отделки, уборы, перчатки, рукавицы, чулки, носки, спальные мешки, покрывала и другие аналогичные изделия;',
  },
  {
    title: 'Шкурки меховые выделанные;',
  },
];