import React from 'react';
import type { InputProps as RcInputProps } from 'rc-input';
import telegram from 'assets/images/telegram.png';
import viber from 'assets/images/viber.png';
import whatsApp from 'assets/images/whatsApp.png';
import styles from './styles.module.scss';


// export const Input = (props: InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement> | null) => {

// interface Props extends React.HTMLAttributes {
export interface InputProps extends Omit<RcInputProps, 'wrapperClassName' | 'groupClassName' | 'inputClassName' | 'affixWrapperClassName'> {
  error?: boolean;
  disabled?: boolean;
  errorText?: string;
  [key: `data-${string}`]: string | undefined;
}

export const CheckboxSocial = (props: InputProps) => {
  const uid = props.id || `for__${Date.now()}`;

  return (
    <React.Fragment>
      <input
        id={ uid }
        type={ props.type }
        className={ styles.checkbox }
        value={ props.value }
        placeholder={ props.placeholder }
        onChange={ props.onChange }
      />
      <label
        className={ styles.label }
        htmlFor={ uid }
      >
        <div className={ styles.social }>
          <img src={ whatsApp } alt="" />
          <img src={ telegram } alt="" />
          <img src={ viber } alt="" />
        </div>
        <div className={ styles.label__title }>Использовать мессенджер</div>
      </label>
      { props.errorText && <div className={ styles.error }>{ props.errorText }</div> }
    </React.Fragment>
  );
};

CheckboxSocial.defaultProps = {
  type: 'checkbox',
  size: 'md',
  placeholder: 'Ваше значение',
};