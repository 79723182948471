import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchers } from 'api';
import type { RootState } from 'store/index';
import * as Api from 'types/api';
import { Pagination, Sort } from 'types/pagination';
import { TReviewImage, TReviewText } from 'types/reviews';

export const getReviewsText = createAsyncThunk('reviews/getReviewsText', async (payload: Api.GetReviewsLoad, { getState }) => {
  const { reviews } = getState() as RootState;

  const response = await fetchers.getReviews({
    variant: 'text',
    pagination: {
      ...payload.pagination,
      sortOrder: reviews.textPagination.sortOrder,
    },
  });

  return response.data;
},
);

export const getReviewsImage = createAsyncThunk('reviews/getReviewsImage', async (payload: Api.GetReviewsLoad, { getState }) => {
  const { reviews } = getState() as RootState;

  const response = await fetchers.getReviews({
    variant: 'image',
    pagination: {
      ...payload.pagination,
      sortOrder: reviews.imagePagination.sortOrder,
    },
  });

  return response.data;
},
);

export const createReview = createAsyncThunk('reviews/createReview', async (payload: Api.CreateReviews) => {
  const response = await fetchers.createReview(payload);

  return response.data;
},
);

// Define a type for the slice state
interface AppState {
  loading: {
    getImage: boolean;
    getText: boolean;
    create: boolean;
  };
  image: TReviewImage[] | null;
  text: TReviewText[] | null;
  imagePagination: Pagination & Sort;
  textPagination: Pagination & Sort;
}

// Define the initial state using that type
const initialState: AppState = {
  loading: {
    getImage: false,
    getText: false,
    create: false,
  },
  image: null,
  text: null,
  imagePagination: {
    page: 1,
    size: 8,
    itemCount: 0,
    pageSize: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    sortField: 'createdAt',
    sortOrder: 'asc',
  },
  textPagination: {
    page: 1,
    size: 8,
    itemCount: 0,
    pageSize: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    sortField: 'createdAt',
    sortOrder: 'asc',
  },
};

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    clearReviews: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getReviewsText.pending, (state) => {
      state.loading.getText = true;
    });
    builder.addCase(getReviewsText.rejected, (state) => {
      state.loading.getText = false;
    });
    builder.addCase(getReviewsText.fulfilled, (state, action) => {
      state.loading.getText = false;
      state.text = Array.isArray(state.text) ? [...state.text, ...action.payload.data] : action.payload.data;
      state.textPagination = action.payload.pagination;
    });
    builder.addCase(getReviewsImage.pending, (state) => {
      state.loading.getImage = true;
    });
    builder.addCase(getReviewsImage.rejected, (state) => {
      state.loading.getImage = false;
    });
    builder.addCase(getReviewsImage.fulfilled, (state, action) => {
      state.loading.getImage = false;
      state.image = Array.isArray(state.image) ? [...state.image, ...action.payload.data] : action.payload.data;
      state.imagePagination = action.payload.pagination;
    });
    builder.addCase(createReview.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(createReview.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(createReview.fulfilled, (state) => {
      state.loading.create = false;
    });
  },
});

export const { clearReviews } = reviewsSlice.actions;

export const selectReviewsText = (state: RootState) => state.reviews.text;
export const selectReviewsTextPagination = (state: RootState) => state.reviews.textPagination;

export const selectReviewsImage = (state: RootState) => state.reviews.image;
export const selectReviewsImagePagination = (state: RootState) => state.reviews.imagePagination;

export const selectReviewsLoading = (state: RootState) => state.reviews.loading;

export default reviewsSlice.reducer;