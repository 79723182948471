import { useState } from 'react';
import classNames from 'classnames';
import avaExpert from 'assets/images/ava_expert.png';
import { QuizForm } from './form';
import { questions } from './questions';
import styles from './styles.module.scss';
import { QuizVariant } from './variant';

export const Quiz = () => {
  const [page, setPage] = useState(1);
  const pageMax = questions.length || 0;
  const isDisabled = page === pageMax;
  const [error, setError] = useState('');
  const [value, setValue] = useState('');
  const [result, setResult] = useState<any>([]);

  const stylesQuiz = classNames({
    [styles.quiz]: true,
    [styles.quiz_isForm]: isDisabled,
  });

  const onHandleClick = (txt: string) => {
    const question = questions[page - 1];

    setResult(((prev: any) => [...prev, {
      title: question.title,
      variant: txt,
    }]));
    setValue(txt);
  };

  const onNext = () => {
    const nextPage = page + 1;

    if (!value) {
      setError('Пожалуйста выберите вариант');

      return false;
    }

    setError('');
    setValue('');

    if (nextPage >= questions.length) {
      setPage(questions.length);
    } else {
      setPage(nextPage);
    }
  };

  return (
    <div className={ stylesQuiz }>
      { isDisabled ? (
        <QuizForm qvalue={ result } />
      ) : (
        <div className={ styles.content }>
          <div className={ styles.questions }>
            <div className={ styles.questions__title }>
              { questions[page - 1].title }
            </div>
            <div className={ styles.questions_variants }>
              { questions[page - 1].variants.map((item, i) => (
                <QuizVariant
                  key={ i }
                  img={ item.img }
                  defaultValue={ item.value }
                  value={ value }
                  onClick={ () => onHandleClick(item.value) }
                />
              )) }
            </div>
          </div>
          { error && (<div className={ styles.questions__error }>{ error }</div>) }
          <div className={ styles.navigate }>
            <button
              type="submit"
              disabled={ isDisabled }
              onClick={ onNext }
              className={ styles.btn }
            >
              Далее
            </button>
            <div className={ styles.navigate__size }>Вопрос { page } из { pageMax }</div>
          </div>
        </div>
      ) }
      <div className={ styles.contact }>
        <div className={ styles.contact__title }>
          Бонусы после прохождения теста:
        </div>
        <div className={ styles.contact_list }>
          <div className={ styles.contact_list__item }>
            <i className='icon-plus' />
            <div>бесплатная <br />
              консультация эксперта</div>
          </div>
          <div className={ styles.contact_list__item }>
            <i className='icon-plus' />
            <div>скидка <span>25%</span> на оформление <b></b> документов</div>
          </div>
          <div className={ styles.contact_list__info }>
            <img src={ avaExpert } alt="" />
            <div>
              Юлия Мишина <br />
              Эксперт по сертификации
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
