import { useState } from 'react';
import { QuestionToExpertModal } from 'components/questionToExpert';
import ava from 'assets/images/ava_expert.png';
import styles from './styles.module.scss';


export const AskExpertQuestion = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className={ styles.askExpert }>
      <div className={ styles.contact }>
        <div className={ styles.contact__ava }>
          <img src={ ava } alt="" />
        </div>
        <div className={ styles.contact__text }>
          Консультация от профессионального эксперта. <br />
          <div>Объясним какой документ необходим для вашей продукции</div>
        </div>
      </div>
      <div className={ styles.desc }>
        <b>Юлия Мишина.</b> Эксперт по сертификации. Опыт более 5 лет
      </div>
      <div
        className={ styles.link }
        onClick={ () => setToggle(true) }
      >
        Задать вопрос эксперту
      </div>
      <QuestionToExpertModal open={ toggle } onClose={ () => setToggle(false) } />
    </div>
  );
};
