import React, { useState } from 'react';
import { SuccessfullySent } from 'components/modal';
import { Input } from 'components/ui/form';
import Modal from 'components/ui/modal';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { selectEmailRequestPhoneСallLoading, SendRequestPhoneСall } from 'store/requestPhoneСall';
import { TPhoneСall } from 'types/form';
import * as yup from 'yup';
import styles from './styles.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
};

const schema = yup.object().shape({
  thema: yup.string(),
  contacts: yup.string().required('Обязательно заполните поле'),
});

export const RequestPhoneСallModal = ({ open, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectEmailRequestPhoneСallLoading);
  const [successOpen, setSuccessOpen] = useState(false);

  const modalClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <Modal
        open={ open }
        onCancel={ modalClose }
        className={ styles.modal }
      >
        <div className={ styles.title }>Оставьте свой номер телефона и мы <br /> перезвоним вам в течение 10 мин.</div>
        <div className={ styles.form }>
          <Formik<TPhoneСall>
            initialValues={ { contacts: '', type_message: 'Заказ обратного звонка' } }
            validationSchema={ schema }
            onSubmit={ (values, { resetForm, setSubmitting }) => {
              dispatch(SendRequestPhoneСall(values)).unwrap()
                .then(() => {
                  setSuccessOpen(true);
                  setSubmitting(false);
                  resetForm();
                  modalClose();
                });
            } }
          >
            { ({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              /* and other goodies */
            }) => (
              <form
                className={ styles.form_template }
                onSubmit={ handleSubmit }
              >
                <Input
                  type='hidden'
                  id="type_message"
                  name='type_message'
                  value="Заказ обратного звонка"
                />
                <InputMask
                  id='contacts'
                  value={ values.contacts }
                  mask='+7 (999) 999-99-99'
                  placeholder='+7 (999) 999-99-99'
                  className={ styles.input }
                  onChange={ handleChange }
                  onBlur={ handleBlur }
                />
                <button
                  type="submit"
                  disabled={ isSubmitting || Boolean(errors.contacts) || loading }
                  className={ styles.btn }
                >
                  Жду звонка
                </button>
              </form>
            )
            }
          </Formik>
        </div>
        <p className={ styles.info }>Нажимая кнопку, вы даете согласие на <b>обработку персональных данных</b></p>
      </Modal>
      <SuccessfullySent
        open={ successOpen }
        onCancel={ () => setSuccessOpen(false) }
        title="Спасибо! Ваша заявка успешно отправлена, мы <br /> свяжемся с вами в течение 10 мин."
      />
    </React.Fragment>
  );
};