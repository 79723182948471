import { CalculateCostTerms } from 'components/form';
import { AskExpertQuestion } from 'components/ui/askExpertQuestion';
import imgBanner1000 from 'assets/images/banner1000.png';
import imgBanner1920 from 'assets/images/banner1920.png';
import imgBanner480 from 'assets/images/banner480.png';
import imgBanner768 from 'assets/images/banner768.png';
import styles from './styles.module.scss';

type Props = {
  title: string;
};

export default function Banner({ title }: Props) {
  return (
    <div className={ styles.banner }>
      <picture className={ styles.banner__picture }>
        <source media="(min-width: 1001px)" srcSet={ imgBanner1920 } />
        <source media="(min-width: 769px)" srcSet={ imgBanner1000 } />
        <source media="(min-width: 481px)" srcSet={ imgBanner768 } />
        <img src={ imgBanner480 } className={ styles.banner__img } alt="" />
      </picture>
      <div className={ styles.container }>
        <div className={ styles.content }>
          <div className={ styles.short }>От аккредитованного органа по сертификации <span>№ RA.RU.11АМ05</span></div>
          <h1 className={ styles.title } dangerouslySetInnerHTML={ { __html: title } } />
          <div className={ styles.event }>
            <div className={ styles.event__item }><i className='icon icon-plus' /> Крупный центр сертификации с 2010 года</div>
            <div className={ styles.event__item }><i className='icon icon-plus' /> Аттестованные эксперты всегда на связи</div>
            <div className={ styles.event__item }><i className='icon icon-plus' /> Работаем по всей России</div>
          </div>
          <AskExpertQuestion />
        </div>
        <div className={ styles.form }>
          <CalculateCostTerms />
        </div>
      </div>
    </div>
  );
}
