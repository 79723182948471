import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { QuestionToExpertModal } from 'components/questionToExpert';
import { Button } from 'components/ui/button';
import Link from 'components/ui/link';
import { DocumentCardSkeleton } from 'components/ui/skeleton';
import { useSelector } from 'react-redux';
import { router } from 'routers/router';
import { getCertificates, selectCertificateLoading, selectCertificates } from 'store/certificates';
import { useAppDispatch } from 'store/hooks';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import DocumentCard from './card';
import styles from './styles.module.scss';

const DocumentsForConfirmation = () => {
  const dispatch = useAppDispatch();
  const certificates = useSelector(selectCertificates);
  const loading = useSelector(selectCertificateLoading);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    dispatch(getCertificates({ pagination: { page: 1 } }));
  }, [dispatch]);

  return (
    <div className={ styles.background }>
      <div className="container">
        <div className={ styles.row }>
          <div className={ cn(styles.heading, 'heading') }>
            <h2>Предоставляем все документы для подтверждения соответсвия</h2>
          </div>
          <Link href={ router.services.path }><Button color="darken" ttu={ true }>Показать все услуги</Button></Link>
        </div>
        <div className="columns">
          <div className={ styles.slider }>
            <div className={ styles.navigation }>
              <div className={ styles.navigationPrev }><i className='icon-angle-left' /></div>
              <div className={ styles.navigationNext }><i className='icon-angle-right' /></div>
            </div>
            <Swiper
              itemScope={ true }
              itemType="https://schema.org/Product"
              className={ styles.swiper }
              modules={ [Navigation] }
              spaceBetween={ 20 }
              slidesPerView={ 1 }
              navigation={ {
                prevEl: `.${styles.navigationPrev}`,
                nextEl: `.${styles.navigationNext}`,
              } }
              breakpoints={ {
                481: {
                  slidesPerView: 2,
                },
                769: {
                  slidesPerView: 3,
                },
                1001: {
                  slidesPerView: 4,
                },
              } }
            >
              { loading.get ? Array(4).fill(null).map((_, i) => (
                <SwiperSlide key={ i } className={ styles.swiperSlider }>
                  <DocumentCardSkeleton />
                </SwiperSlide>
              )) : (
                certificates?.map((item, i) => (
                  <SwiperSlide key={ i } className={ styles.swiperSlider }>
                    <DocumentCard data={ item } />
                  </SwiperSlide>
                ))
              ) }
            </Swiper>
          </div>
          <div className={ styles.link } onClick={ () => setToggle(true) }>Какой сертификат нужен для моей продукции?</div>
          <QuestionToExpertModal open={ toggle } onClose={ () => setToggle(false) } />
        </div>
      </div>
    </div>
  );
};

export default DocumentsForConfirmation;
