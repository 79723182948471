import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { navigateRouter } from 'routers/router';
import styles from './styles.module.scss';

type PItem = {
  url?: string;
  name: string;
};

const AppNavigationItem = ({ url, name }: PItem) => url ? (
  <React.Fragment key={ name }>
    <span>—</span>
    <Link to={ url } key={ url } itemProp="url item name">{ name }</Link>
  </React.Fragment>
) : (
  <React.Fragment key={ name } >
    <span>—</span>
    <span itemProp="item name">{ name }</span>
  </React.Fragment>
);


export const AppNavigation = React.memo(() => {
  const location = useLocation();
  const liks = navigateRouter();
  const arrNav = [<Link to="/" key={ '/homes' } itemProp="item name">Главная</Link>];

  const paths = location.pathname.split('/');


  if (paths.length <= 1) {
    return null;
  }

  for (let i = 1; i < paths.length; i++) {
    const last = i === paths.length - 1;
    const item = paths[i];

    if (last) {
      arrNav.push(AppNavigationItem({
        name: liks[`/${item}`],
      }));
    } else {
      arrNav.push(AppNavigationItem({
        name: liks[`/${item}`],
        url: `/${item}`,
      }));
    }
  }

  return (
    <div className={ styles.navigation } itemScope={ true } itemType="https://schema.org/BreadcrumbList">
      { arrNav }
    </div>
  );
});
